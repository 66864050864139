"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FontFamily = exports.REGEX_OTP = exports.REGEX_PASSWORD = exports.REGEX_KANA = exports.REGEX_EMAIL = exports.urlDemo = exports.LANGUAGE_CODE = void 0;
const react_native_1 = require("react-native");
exports.LANGUAGE_CODE = {
    vi: "vi",
    ja: "ja",
    en: "en",
};
exports.urlDemo = "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS0kc_TLq5-Re1X8tZ5m9ZRgMdAjKWObY8DeA&usqp=CAU";
exports.default = {
    ACCESS_TOKEN_LOCAL: "ACCESS_TOKEN_LOCAL",
    REFRESH_TOKEN_LOCAL: "REFRESH_TOKEN_LOCAL",
    IN_ACTIVE: "Inactive",
    ACTIVE: "Active",
    FCM_TOKEN: "FCM_TOKEN",
};
exports.REGEX_EMAIL = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
exports.REGEX_KANA = /^([ァ-ン ｧ-ﾝ　ﾞﾟ]|ー)+$/;
exports.REGEX_PASSWORD = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*?&]{8,20}$/;
exports.REGEX_OTP = /^[0-9]{6}$/;
exports.FontFamily = {
    NotoSerif: react_native_1.Platform.select({
        web: { fontFamily: "Noto Serif JP" },
        default: {
            fontFamily: "NotoSerifJP-Regular",
        },
    }),
    NotoSerif200: react_native_1.Platform.select({
        web: { fontFamily: "Noto Serif JP", fontWeight: "200" },
        default: {
            fontFamily: "NotoSerifJP-ExtraLight",
        },
    }),
    NotoSerif300: react_native_1.Platform.select({
        web: { fontFamily: "Noto Serif JP", fontWeight: "300" },
        default: {
            fontFamily: "NotoSerifJP-Light",
        },
    }),
    NotoSerif400: react_native_1.Platform.select({
        web: { fontFamily: "Noto Serif JP", fontWeight: "400" },
        default: {
            fontFamily: "NotoSerifJP-Regular",
        },
    }),
    NotoSerif500: react_native_1.Platform.select({
        web: { fontFamily: "Noto Serif JP", fontWeight: "500" },
        default: {
            fontFamily: "NotoSerifJP-Medium",
        },
    }),
    NotoSerif600: react_native_1.Platform.select({
        web: { fontFamily: "Noto Serif JP", fontWeight: "600" },
        default: {
            fontFamily: "NotoSerifJP-SemiBold",
        },
    }),
    NotoSerif700: react_native_1.Platform.select({
        web: { fontFamily: "Noto Serif JP", fontWeight: "700" },
        default: {
            fontFamily: "NotoSerifJP-Bold",
        },
    }),
    NotoSerif800: react_native_1.Platform.select({
        web: { fontFamily: "Noto Serif JP", fontWeight: "800" },
        default: {
            fontFamily: "NotoSerifJP-Bold",
        },
    }),
    NotoSerif900: react_native_1.Platform.select({
        web: { fontFamily: "Noto Serif JP", fontWeight: "900" },
        default: {
            fontFamily: "NotoSerifJP-Black",
        },
    }),
};

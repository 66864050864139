"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_native_1 = require("react-native");
;
const Input = ({ placeholder = 'placeholder', onChangeText, value, style, secureTextEntry, }) => {
    return ((0, jsx_runtime_1.jsx)(react_native_1.TextInput, { placeholderTextColor: '#CCCCCC', placeholder, value, onChangeText, secureTextEntry, style: [styles.textInput, style] }));
};
const styles = react_native_1.StyleSheet.create({
    textInput: {
        fontSize: 15,
        borderColor: '#999999',
        borderWidth: 0.5,
        borderRadius: 4,
        height: 50,
        backgroundColor: 'white',
        width: '100%',
        paddingHorizontal: 10,
    },
});
exports.default = Input;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const scale_1 = require("../../utils/scale");
const react_native_1 = require("react-native");
const button_1 = require("../button");
const lodash_1 = require("lodash");
const constants_1 = require("../../constants");
const ModalConfirm = (props) => {
    const { visible, onClose, title, content } = props;
    return ((0, jsx_runtime_1.jsx)(react_native_1.Modal, { visible: visible, animationType: "none", statusBarTranslucent: true, transparent: true, onRequestClose: () => {
            onClose();
        }, ...props, children: (0, jsx_runtime_1.jsx)(react_native_1.TouchableWithoutFeedback, { onPress: () => {
                onClose();
            }, children: (0, jsx_runtime_1.jsx)(react_native_1.View, { style: styles.viewContain, children: (0, jsx_runtime_1.jsxs)(react_native_1.View, { style: styles.viewModal, children: [(0, jsx_runtime_1.jsx)(react_native_1.Text, { style: styles.textTitle, children: title }), (0, jsx_runtime_1.jsx)(react_native_1.Text, { style: [styles.textTitle1, { textAlign: 'center' }], children: content }), (0, jsx_runtime_1.jsx)(react_native_1.View, { style: {
                                marginTop: (0, scale_1.scale)(10),
                            }, children: (0, jsx_runtime_1.jsx)(react_native_1.View, { style: {
                                    width: '100%',
                                    alignItems: 'center',
                                }, children: (0, jsx_runtime_1.jsx)(button_1.GradientButton, { title: "\u9589\u3058\u308B", style: styles.button, onPress: () => {
                                        onClose();
                                    } }) }) })] }) }) }) }));
};
const styles = react_native_1.StyleSheet.create({
    btn: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
    },
    vBtn: {
        height: (0, scale_1.scale)(62),
        width: "100%",
        borderTopWidth: 1,
        borderTopColor: "#CCCCCC",
        flexDirection: "row",
    },
    textTitle: {
        marginTop: (0, scale_1.scale)(0),
        marginBottom: (0, scale_1.scale)(10),
        color: "#000000",
        fontSize: (0, scale_1.moderateScale)(20),
        lineHeight: (0, scale_1.moderateScale)(40),
        ...constants_1.FontFamily.NotoSerif700,
    },
    textTitle1: {
        fontSize: (0, scale_1.moderateScale)(14),
        lineHeight: (0, scale_1.moderateScale)(22),
        color: "#0E0E0E",
        ...constants_1.FontFamily.NotoSerif700,
    },
    viewModal: {
        backgroundColor: "#FFFFFF",
        alignItems: "center",
        borderRadius: (0, scale_1.scale)(8),
        paddingVertical: (0, scale_1.scale)(20),
    },
    viewContain: {
        flex: 1,
        backgroundColor: "#101C2D66",
        alignItems: "center",
        justifyContent: "center",
    },
    line: {
        width: "100%",
        height: 1,
        backgroundColor: "#F0F3F4",
        marginTop: (0, scale_1.scale)(20),
    },
    input: {
        width: "100%",
        height: (0, scale_1.scale)(42),
        borderWidth: 1,
        borderColor: "#A5A5A5",
        ...constants_1.FontFamily.NotoSerif,
        marginTop: (0, scale_1.scale)(10),
        paddingHorizontal: (0, scale_1.scale)(40),
    },
    button: {
        marginVertical: (0, scale_1.scale)(20),
        height: (0, scale_1.scale)(52),
        marginHorizontal: (0, scale_1.scale)(40),
        borderRadius: (0, scale_1.scale)(10),
        width: (0, lodash_1.min)([400, react_native_1.Dimensions.get("window").width * 0.7]),
    },
    err: {
        color: "#FF0000",
        fontSize: (0, scale_1.moderateScale)(13),
        marginBottom: (0, scale_1.scale)(10),
    },
});
exports.default = ModalConfirm;

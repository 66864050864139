"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_native_1 = require("react-native");
const scale_1 = require("../../../../utils/scale");
const constants_1 = require("../../../../constants");
exports.default = react_native_1.StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#FCF9F2",
    },
    nameContainer: {
        height: 70,
        backgroundColor: "#FFFFFF",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: (0, scale_1.scale)(10),
        flexDirection: "row",
    },
    headerContainer: {
        backgroundColor: "#F3F3F3",
        justifyContent: "center",
        height: 70,
        width: "100%",
        position: "absolute",
        top: 0,
        shadowColor: "#000",
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.2,
        marginBottom: 10,
        elevation: 2,
        zIndex: 100,
    },
    scrollContainer: {
        width: "90%",
        height: "100%",
        backgroundColor: "#FCF9F2",
        marginTop: 70,
    },
    headerText: {
        color: "#CF7285",
        ...constants_1.FontFamily.NotoSerif700,
        fontSize: 20,
        marginLeft: 20,
    },
    userNameText: {
        color: "#334856",
        ...constants_1.FontFamily.NotoSerif700,
        fontSize: 15,
        position: "absolute",
        left: 20,
    },
    menuContainer: {
        width: "100%",
        marginTop: 20,
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        overflow: "hidden",
        flexWrap: "wrap",
    },
    menuItemContainer: {
        height: (0, scale_1.fontsize)(190, 210),
        width: (0, scale_1.fontsize)(160, 180),
        backgroundColor: "transparent",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: 30,
    },
    menuItem: {
        height: (0, scale_1.fontsize)(160, 170),
        width: (0, scale_1.fontsize)(160, 180),
        backgroundColor: "#CF7285",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 30,
        marginTop: 10,
    },
    menuItemText: {
        color: "#CF7285",
        ...constants_1.FontFamily.NotoSerif700,
        fontSize: (0, scale_1.fontsize)(12, 18),
        marginTop: 17,
    },
    buttonContainer: {
        justifyContent: "center",
        marginTop: 20,
        alignItems: "center",
        height: (0, scale_1.scale)(52),
        borderRadius: (0, scale_1.scale)(26),
        alignSelf: "center",
    },
    footerText: {
        color: "#6E8CA0",
        ...constants_1.FontFamily.NotoSerif,
        fontSize: 12,
        marginVertical: 20,
        textAlign: "center",
    },
});

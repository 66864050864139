"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_native_1 = require("react-native");
const scale_1 = require("../../../utils/scale");
const lodash_1 = require("lodash");
exports.default = react_native_1.StyleSheet.create({
    container: {
        alignItems: 'center',
    },
    loginForm: {
    // justifyContent: "center",
    // marginVertical: 10,
    },
    topIntroText: {
        textAlign: 'center',
        color: '#0E0E0E',
        fontFamily: 'Noto Serif JP',
        fontSize: 14,
        fontWeight: 'bold',
        marginBottom: (0, scale_1.scale)(20),
        marginTop: (0, scale_1.scale)(20),
    },
    introText: {
        paddingTop: 70,
        paddingBottom: 40,
        width: '100%',
        textAlign: 'center',
        color: '#D68F9D',
        fontFamily: 'Noto Serif JP',
        fontSize: 14,
    },
    inputContainer: {
        height: (0, scale_1.scale)(52),
        width: (0, lodash_1.min)([400, react_native_1.Dimensions.get('window').width * 0.7]),
        borderRadius: (0, scale_1.scale)(20),
        backgroundColor: '#A5A5A5',
        marginVertical: (0, scale_1.scale)(10),
        marginHorizontal: (0, scale_1.scale)(20),
        marginTop: (0, scale_1.scale)(10),
        color: 'white',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    input: {
        height: (0, scale_1.scale)(52),
        width: '80%',
        color: 'white',
        paddingHorizontal: (0, scale_1.scale)(10),
        includeFontPadding: false,
        fontFamily: 'Noto Serif JP',
        marginBottom: 5,
        padding: 0,
        ...react_native_1.Platform.select({
            web: {
                outlineStyle: 'none',
            },
        }),
    },
    buttonContainer: {
        width: (0, lodash_1.min)([400, react_native_1.Dimensions.get('window').width * 0.7]),
        justifyContent: 'center',
        marginTop: 30,
        alignItems: 'center',
        height: (0, scale_1.scale)(40),
        borderRadius: (0, scale_1.scale)(20),
        marginHorizontal: 20,
    },
    imageContainer: {
        resizeMode: 'contain',
        height: '30%',
        width: '100%',
    },
    loginAccountText: {
        color: '#0E0E0E',
        fontWeight: '700',
        fontFamily: 'Noto Serif JP',
        fontSize: (0, scale_1.moderateScale)(24),
        textAlign: 'center',
    },
    emailText: {
        color: '#0E0E0E',
        fontWeight: 'bold',
        fontFamily: 'Noto Serif JP',
        fontSize: (0, scale_1.moderateScale)(13),
        textAlign: 'left',
        marginLeft: 35,
    },
    underlineButton: {
        color: 'black',
        textDecorationLine: 'underline',
        fontWeight: '300',
        paddingBottom: 20,
        fontFamily: 'Noto Serif JP',
        fontSize: (0, scale_1.scale)(14),
    },
    underlineButtonContainer: {
        marginTop: 25,
        marginLeft: 30,
    },
    err: {
        color: '#FF0000',
        fontSize: (0, scale_1.moderateScale)(13),
        marginVertical: (0, scale_1.scale)(10),
        marginHorizontal: 20,
    },
});

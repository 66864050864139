"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_native_1 = require("react-native");
const scale_1 = require("../../../../utils/scale");
exports.default = react_native_1.StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#FCF9F2',
    },
    headerContainer: {
        backgroundColor: '#F3F3F3',
        justifyContent: 'flex-start',
        height: 70,
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: 0,
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.2,
        // marginBottom: 10,
        elevation: 1,
    },
    headerText: {
        color: '#CF7285',
        fontFamily: 'Noto Serif JP',
        fontSize: 20,
        fontWeight: 'bold',
        marginLeft: 10,
    },
    scrollContainer: { marginHorizontal: 0, width: '100%', paddingTop: (0, scale_1.scale)(30) },
    policy: {
        color: '#A5A5A5',
        fontFamily: 'Noto Serif JP',
        fontSize: 14,
        lineHeight: (0, scale_1.moderateScale)(22),
        fontWeight: '300',
        marginHorizontal: (0, scale_1.scale)(20),
        marginBottom: (0, scale_1.scale)(15),
    },
    policyTitle: {
        color: '#A5A5A5',
        fontFamily: 'Noto Serif JP',
        fontSize: (0, scale_1.moderateScale)(14),
        lineHeight: (0, scale_1.moderateScale)(22),
        fontWeight: '700',
        marginHorizontal: (0, scale_1.scale)(20),
        marginBottom: (0, scale_1.scale)(15),
    },
    policyContent: {
        color: '#A5A5A5',
        fontFamily: 'Noto Serif JP',
        fontSize: (0, scale_1.moderateScale)(14),
        lineHeight: (0, scale_1.moderateScale)(22),
        fontWeight: '300',
        marginHorizontal: (0, scale_1.scale)(20),
    },
});

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_native_1 = require("react-native");
const scale_1 = require("../../../../utils/scale");
exports.default = react_native_1.StyleSheet.create({
    container: {
        alignItems: 'center',
        // justifyContent: 'space-between',
        // position: 'absolute',
        marginTop: (0, scale_1.scale)(20),
    },
    introText: {
        marginVertical: 20,
        marginHorizontal: 40,
        fontWeight: '300',
        textAlign: 'center',
        color: '#0E0E0E',
        fontFamily: 'Noto Serif JP',
        fontSize: 14,
    },
    headerText: {
        color: '#0E0E0E',
        fontWeight: '700',
        marginTop: (0, scale_1.scale)(50),
        fontFamily: 'Noto Serif JP',
        fontSize: 24,
        textAlign: 'center',
    },
    loadingIndicator: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundColor: '#F5FCFF88',
        alignItems: 'center',
        justifyContent: 'center',
    },
    bottomContainer: {
        flexDirection: 'row',
        // marginBottom: 20,
        marginHorizontal: 20,
        width: (0, scale_1.moderateScale)(200),
        paddingBottom: (0, scale_1.scale)(40),
    },
    cancel: {
        color: '#797E80',
        fontWeight: '700',
        marginTop: (0, scale_1.scale)(50),
        fontFamily: 'Noto Serif JP',
        fontSize: (0, scale_1.moderateScale)(13),
        textAlign: 'left',
    },
    withdraw: {
        color: '#CE146A',
        fontWeight: '700',
        marginTop: (0, scale_1.scale)(50),
        fontFamily: 'Noto Serif JP',
        fontSize: (0, scale_1.moderateScale)(13),
        textAlign: 'right',
    },
});

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.listFAQ = exports.listNotification = void 0;
exports.listNotification = [
    {
        id: '1',
        name: 'セラピスト名',
        content: 'ここにはプロフィールテキストが入ります。ここにはプロフィールテキストが入ります。ここにはプロフィールテキストが入ります。ここにはプロフィールテキストが入ります。',
        pubished_at: '10月24日',
    },
    {
        id: '2',
        name: 'セラピスト名',
        content: 'ここにはプロフィールテキストが入ります。ここにはプロフィールテキストが入ります。ここにはプロフィールテキストが入ります。ここにはプロフィールテキストが入ります。',
        pubished_at: '10月20日',
    },
];
exports.listFAQ = [
    {
        id: '1',
        q: 'ここには質問が入ります',
        a: 'ここには回答が入ります',
        expanded: false,
    },
    {
        id: '2',
        q: 'ここには質問が入ります',
        a: 'ここには回答が入ります',
        expanded: false,
    },
    {
        id: '3',
        q: 'ここには質問が入ります',
        a: 'ここには回答が入ります',
        expanded: false,
    },
    {
        id: '4',
        q: 'ここには質問が入ります',
        a: 'ここには回答が入ります',
        expanded: false,
    },
    {
        id: '5',
        q: 'ここには質問が入ります',
        a: 'ここには回答が入ります',
        expanded: false,
    },
    {
        id: '6',
        q: 'ここには質問が入ります',
        a: 'ここには回答が入ります',
        expanded: false,
    },
    {
        id: '7',
        q: 'ここには質問が入ります',
        a: 'ここには回答が入ります',
        expanded: false,
    },
    {
        id: '8',
        q: 'ここには質問が入ります',
        a: 'ここには回答が入ります',
        expanded: false,
    },
];

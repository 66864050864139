"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.homeReducer = exports.setHomeDataErrorAction = exports.setHomeDataAction = exports.setErrorMessageAction = exports.setErrorsAction = exports.LoginState = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
exports.LoginState = {
    IS_UPDATE_INFO: 'IS_UPDATE_INFO',
    IS_LOGGED: 'IS_LOGGED',
    IS_NEED_LOGIN: 'IS_NEED_LOGIN',
    FIRST_SIGN_IN: 'FIRST_SIGN_IN',
    SELECT_PLAN: 'SELECT_PLAN',
    NOTHING: 'NOTHING',
};
const initialState = {
    accessToken: null,
    isLoading: false,
    errors: [],
    errorMessage: '',
};
exports.setErrorsAction = (0, toolkit_1.createAction)('setErrors');
exports.setErrorMessageAction = (0, toolkit_1.createAction)('setErrorMessage');
exports.setHomeDataAction = (0, toolkit_1.createAction)('setHomeData');
exports.setHomeDataErrorAction = (0, toolkit_1.createAction)('setHomeDataErrorAction');
const homeSlice = (0, toolkit_1.createSlice)({
    name: 'home',
    initialState,
    reducers: {
        home: state => {
            return { ...state, isLoading: true };
        },
    },
    // child action of login
    extraReducers: builder => {
        builder.addCase(exports.setErrorsAction, (state, action) => {
            return {
                ...state,
                errors: action.payload,
                isLoading: false,
            };
        });
        builder.addCase(exports.setErrorMessageAction, (state, action) => {
            return {
                ...state,
                errorMessage: action.payload,
                isLoading: false,
            };
        });
    },
});
exports.homeReducer = homeSlice.reducer;
exports.default = homeSlice;

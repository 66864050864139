"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_native_svg_1 = __importStar(require("react-native-svg"));
const scale_1 = require("../../utils/scale");
const constants_1 = require("../../constants");
const IconSS = (props) => ((0, jsx_runtime_1.jsxs)(react_native_svg_1.default, { width: (0, scale_1.scale)(60), height: (0, scale_1.scale)(60), viewBox: "0 0 72 72", ...props, children: [(0, jsx_runtime_1.jsx)(react_native_svg_1.Defs, { children: (0, jsx_runtime_1.jsxs)(react_native_svg_1.LinearGradient, { id: "a", x1: 0.889, y1: 0.157, x2: 0.148, y2: 0.898, gradientUnits: "objectBoundingBox", children: [(0, jsx_runtime_1.jsx)(react_native_svg_1.Stop, { offset: 0, stopColor: "#20201f" }), (0, jsx_runtime_1.jsx)(react_native_svg_1.Stop, { offset: 1, stopColor: "#2e2e2e" })] }) }), (0, jsx_runtime_1.jsxs)(react_native_svg_1.G, { "data-name": "Group 421", children: [(0, jsx_runtime_1.jsx)(react_native_svg_1.G, { "data-name": "Group 159", children: (0, jsx_runtime_1.jsxs)(react_native_svg_1.G, { "data-name": "Ellipse 36", stroke: "#343434", fill: "url(#a)", children: [(0, jsx_runtime_1.jsx)(react_native_svg_1.Circle, { cx: 36, cy: 36, r: 36, stroke: "none" }), (0, jsx_runtime_1.jsx)(react_native_svg_1.Circle, { cx: 36, cy: 36, r: 35.5, fill: "none" })] }) }), (0, jsx_runtime_1.jsx)(react_native_svg_1.Text, { transform: "translate(36 50)", fill: "#a2a1a1", fontSize: 36, fontFamily: constants_1.FontFamily.NotoSerif700.fontFamily, fontWeight: constants_1.FontFamily.NotoSerif700?.fontWeight, "data-name": "Group 161", children: (0, jsx_runtime_1.jsx)(react_native_svg_1.TSpan, { x: -21.384, y: 0, children: "SS" }) })] })] }));
exports.default = IconSS;

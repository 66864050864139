"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.REGEX_OTP = exports.REGEX_PASSWORD = exports.REGEX_KANA = exports.REGEX_EMAIL = exports.urlDemo = exports.LANGUAGE_CODE = void 0;
exports.LANGUAGE_CODE = {
    vi: "vi",
    ja: "ja",
    en: "en",
};
exports.urlDemo = "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS0kc_TLq5-Re1X8tZ5m9ZRgMdAjKWObY8DeA&usqp=CAU";
exports.default = {
    ACCESS_TOKEN_LOCAL: "ACCESS_TOKEN_LOCAL",
    REFRESH_TOKEN_LOCAL: "REFRESH_TOKEN_LOCAL",
    IN_ACTIVE: "Inactive",
    ACTIVE: "Active",
    FCM_TOKEN: "FCM_TOKEN",
};
exports.REGEX_EMAIL = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
exports.REGEX_KANA = /^([ァ-ン ｧ-ﾝ　ﾞﾟ]|ー)+$/;
exports.REGEX_PASSWORD = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*?&]{8,20}$/;
exports.REGEX_OTP = /^[0-9]{6}$/;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_native_1 = require("react-native");
const scale_1 = require("../../../utils/scale");
const lodash_1 = require("lodash");
const constants_1 = require("../../../constants");
exports.default = react_native_1.StyleSheet.create({
    container: {
        alignItems: "center",
        justifyContent: "space-between",
        position: "absolute",
        bottom: 50,
        flex: 1,
    },
    loginForm: {
        justifyContent: "center",
    },
    inputContainer: {
        height: (0, scale_1.scale)(40),
        width: (0, lodash_1.min)([400, react_native_1.Dimensions.get("window").width * 0.7]),
        marginVertical: 10,
        marginHorizontal: 20,
        marginTop: 20,
        color: "white",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
    },
    textContainer: {
        height: "100%",
        borderRadius: (0, scale_1.scale)(20),
        backgroundColor: "#A5A5A5",
        marginHorizontal: 10,
        color: "white",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
    },
    codeContainer: {
        height: (0, scale_1.scale)(52),
        width: (0, lodash_1.min)([400, react_native_1.Dimensions.get("window").width * 0.7]),
        borderRadius: (0, scale_1.scale)(20),
        backgroundColor: "#A5A5A5",
        marginHorizontal: 20,
        marginTop: 10,
        color: "white",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
    },
    input: {
        height: (0, scale_1.scale)(52),
        width: "80%",
        color: "white",
        backgroundColor: "#A5A5A5",
        padding: 0,
        includeFontPadding: false,
        ...constants_1.FontFamily.NotoSerif,
        ...react_native_1.Platform.select({
            web: {
                outlineStyle: "none",
            },
        }),
    },
    buttonContainer: {
        width: (0, lodash_1.min)([400, react_native_1.Dimensions.get("window").width * 0.7]),
        justifyContent: "center",
        marginTop: 50,
        marginHorizontal: 20,
        alignItems: "center",
        height: (0, scale_1.scale)(40),
        borderRadius: (0, scale_1.scale)(20),
        marginBottom: 71,
    },
    imageContainer: {
        position: "absolute",
        resizeMode: "contain",
        top: 40,
        left: 0,
        height: "30%",
        width: "100%",
    },
    loginAccountText: {
        color: "#0E0E0E",
        marginTop: 40,
        ...constants_1.FontFamily.NotoSerif700,
        fontSize: (0, scale_1.moderateScale)(24),
        textAlign: "center",
    },
    passwordText: {
        color: "#0E0E0E",
        ...constants_1.FontFamily.NotoSerif700,
        fontSize: 13,
        textAlign: "left",
        marginHorizontal: 20,
        marginTop: 54,
        marginStart: 43,
    },
    sendOTPText: {
        color: "#FFFFFF",
        ...constants_1.FontFamily.NotoSerif300,
        fontSize: 12,
    },
    err: {
        marginHorizontal: 30,
        color: "#FF0000",
        fontSize: (0, scale_1.moderateScale)(13),
    },
});

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_native_1 = require("react-native");
const scale_1 = require("../../../../utils/scale");
const constants_1 = require("../../../../constants");
exports.default = react_native_1.StyleSheet.create({
    therapistContainer: {
        backgroundColor: "#FCF9F2",
        justifyContent: "space-between",
        paddingBottom: 30,
    },
    containerPreview: {
        flexDirection: "column",
        backgroundColor: "white",
        shadowColor: "#505C62",
        borderRadius: 10,
        marginTop: 20,
        marginHorizontal: 20,
        shadowOffset: { width: 1, height: 1 },
        shadowOpacity: 0.4,
        shadowRadius: 3,
        elevation: 5,
        overflow: "hidden",
    },
    therapistTextContainer: {
        flexDirection: "column",
        overflow: "hidden",
        flex: 1,
    },
    therapistText: {
        color: "#1B1C20",
        ...constants_1.FontFamily.NotoSerif700,
        fontSize: 16,
        marginTop: 20,
    },
    descriptionText: {
        color: "#1B1C20",
        ...constants_1.FontFamily.NotoSerif700,
        fontSize: (0, scale_1.fontsize)(12, 13),
        marginTop: 10,
        paddingBottom: 20,
        marginRight: 20,
    },
    tagContainer: {
        backgroundColor: "#F2A78D",
        height: 24,
        borderRadius: 12,
        alignItems: "center",
        justifyContent: "center",
        marginHorizontal: 2,
        marginVertical: 5,
    },
    tagText: {
        color: "#FFFFFF",
        ...constants_1.FontFamily.NotoSerif700,
        fontSize: 10,
        marginHorizontal: 10,
    },
    feedbackContainer: {
        flexDirection: "column",
        backgroundColor: "white",
        shadowColor: "#505C62",
        borderRadius: 10,
        marginHorizontal: 20,
        shadowOffset: { width: 1, height: 1 },
        shadowOpacity: 0.4,
        shadowRadius: 3,
        elevation: 5,
        overflow: "hidden",
    },
    feedbackTitle: {
        color: "#CF7285",
        ...constants_1.FontFamily.NotoSerif700,
        fontSize: (0, scale_1.fontsize)(14, 18),
        marginHorizontal: 20,
        marginBottom: 13,
        marginTop: 20,
    },
    feedbackDescription: {
        color: "#0E0E0E",
        ...constants_1.FontFamily.NotoSerif700,
        fontSize: (0, scale_1.fontsize)(13, 14),
        marginHorizontal: 20,
        marginBottom: 20,
    },
    notificationContainer: {
        flexDirection: "column",
        backgroundColor: "white",
        shadowColor: "#505C62",
        borderRadius: 10,
        marginHorizontal: 20,
        shadowOffset: { width: 1, height: 1 },
        shadowOpacity: 0.4,
        shadowRadius: 3,
        elevation: 5,
        overflow: "hidden",
        marginTop: 20,
    },
    notificationDate: {
        color: "#CF7285",
        ...constants_1.FontFamily.NotoSerif700,
        fontSize: (0, scale_1.fontsize)(14, 18),
        marginHorizontal: 20,
        marginBottom: 13,
        marginTop: 20,
    },
    notificationContent: {
        color: "#0E0E0E",
        ...constants_1.FontFamily.NotoSerif700,
        fontSize: (0, scale_1.fontsize)(12, 14),
        marginHorizontal: 20,
        marginBottom: 20,
    },
});

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.therapistReducer = exports.setTherapistAction = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const initialState = {
    isLoading: false,
    data: null,
};
exports.setTherapistAction = (0, toolkit_1.createAction)('setTherapist');
const TherapistSlice = (0, toolkit_1.createSlice)({
    name: 'Therapist',
    initialState,
    reducers: {
        getTherapist: (state, action) => {
            return { ...state, action, isLoading: true };
        },
    },
    extraReducers: builder => {
        builder.addCase(exports.setTherapistAction, (state, action) => {
            return {
                ...state,
                data: action.payload,
                isLoading: false,
            };
        });
    },
});
exports.therapistReducer = TherapistSlice.reducer;
exports.default = TherapistSlice;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_native_1 = require("react-native");
const LoadingView = () => {
    return ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: (0, jsx_runtime_1.jsx)(react_native_1.View, { style: {
                flex: 1,
                alignItems: 'center',
                justifyContent: 'center',
                position: 'absolute',
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
            }, children: (0, jsx_runtime_1.jsx)(react_native_1.ActivityIndicator, { size: "large", color: '#CE146A' }) }) }));
};
exports.default = LoadingView;

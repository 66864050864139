"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_native_1 = require("react-native");
const scale_1 = require("../../../utils/scale");
const lodash_1 = require("lodash");
exports.default = react_native_1.StyleSheet.create({
    container: {
        // position: 'absolute',
        // bottom: 40,
        marginTop: (0, scale_1.scale)(10),
        marginHorizontal: 40,
    },
    loginForm: {
        justifyContent: 'center',
    },
    topIntroText: {
        textAlign: 'center',
        color: '#0E0E0E',
        fontFamily: 'Noto Serif JP',
        fontSize: (0, scale_1.moderateScale)(14),
        fontWeight: 'bold',
        marginBottom: (0, scale_1.scale)(13),
        marginTop: (0, scale_1.scale)(20),
        marginHorizontal: 20,
    },
    input: {
        height: (0, scale_1.scale)(52),
        borderRadius: (0, scale_1.scale)(20),
        backgroundColor: '#A5A5A5',
        color: 'white',
        paddingHorizontal: (0, scale_1.scale)(20),
        fontSize: (0, scale_1.moderateScale)(14),
        includeFontPadding: false,
        padding: 0,
        fontFamily: 'Noto Serif JP',
        ...react_native_1.Platform.select({
            web: {
                outlineStyle: 'none',
            },
        }),
    },
    buttonContainer: {
        height: (0, scale_1.scale)(40),
        width: (0, lodash_1.min)([400, react_native_1.Dimensions.get('window').width * 0.7]),
        justifyContent: 'center',
        marginBottom: (0, scale_1.scale)(20),
        alignItems: 'center',
        borderRadius: (0, scale_1.scale)(20),
    },
    emailText: {
        color: '#0E0E0E',
        fontWeight: 'bold',
        fontFamily: 'Noto Serif JP',
        fontSize: (0, scale_1.moderateScale)(13),
        textAlign: 'left',
        marginBottom: (0, scale_1.scale)(10),
        marginHorizontal: 20,
    },
    loginAccountText: {
        color: '#0E0E0E',
        fontWeight: '700',
        marginTop: (0, scale_1.scale)(30),
        fontFamily: 'Noto Serif JP',
        fontSize: (0, scale_1.moderateScale)(24),
        textAlign: 'center',
    },
    textError: {
        fontSize: 14,
        color: 'red',
        marginTop: 10,
        marginBottom: (0, scale_1.scale)(30),
    },
    underlineButton: {
        color: 'black',
        textDecorationLine: 'underline',
        fontWeight: '300',
        paddingBottom: 20,
        fontFamily: 'Noto Serif JP',
        fontSize: (0, scale_1.scale)(14),
    },
    underlineButtonContainer: {
        marginBottom: (0, scale_1.scale)(40),
        marginTop: (0, scale_1.scale)(10),
    },
});

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_native_1 = require("react-native");
const devices_1 = require("../../../../constants/devices");
exports.default = react_native_1.StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: '#FCF9F2',
    },
    headerContainer: {
        backgroundColor: '#F3F3F3',
        justifyContent: 'center',
        height: 70,
        width: '100%',
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.2,
        // marginBottom: 10,
        elevation: 2,
        zIndex: 1000,
    },
    headerText: {
        color: '#CF7285',
        fontFamily: 'Noto Serif JP',
        fontSize: 20,
        fontWeight: 'bold',
        marginLeft: 20,
    },
    therapyContainer: {
        backgroundColor: '#FCF9F2',
        justifyContent: 'space-between',
        paddingBottom: 80,
    },
    therapyPreview: {
        flexDirection: 'column',
        backgroundColor: 'white',
        shadowColor: '#505C62',
        borderRadius: 10,
        marginTop: 20,
        marginHorizontal: 20,
        shadowOffset: { width: 1, height: 1 },
        shadowOpacity: 0.4,
        shadowRadius: 3,
        elevation: 5,
        overflow: 'hidden',
        width: (0, devices_1.isPhone)() ? '90%' : '70%',
    },
    therapyText: {
        color: '#1B1C20',
        fontFamily: 'Noto Serif JP',
        fontSize: 16,
        fontWeight: 'bold',
        marginHorizontal: 12,
        marginVertical: 20,
    },
});

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.listSort = void 0;
exports.listSort = [
    {
        value: 'recommend',
        name: 'おすすめ順',
    },
    {
        value: 'rank',
        name: 'ランク順',
    },
    {
        value: 'name',
        name: '名前順',
    },
    {
        value: 'random',
        name: 'ランダム',
    },
];

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_native_1 = require("react-native");
const devices_1 = require("../../../../constants/devices");
const scale_1 = require("../../../../utils/scale");
const react_1 = require("react");
const constants_1 = require("../../../../constants");
const CategoryOverlayContainer = (props) => {
    const [data, setData] = (0, react_1.useState)([]);
    (0, react_1.useEffect)(() => {
        if (props.data != null && props.data?.length > 1) {
            const newData = props.data.slice(0);
            newData.splice(1, 0, { id: 0, name: 'すべて' });
            setData(newData);
        }
        else {
            setData([
                { id: 1, name: 'PICK UP' },
                { id: 0, name: 'すべて' },
            ]);
        }
        toggleCategoryVisibility();
    }, [props]);
    const height = (0, react_1.useRef)(new react_native_1.Animated.Value(react_native_1.Dimensions.get('window').height)).current;
    const toggleCategoryVisibility = () => {
        react_native_1.Animated.timing(height, {
            toValue: props.visible ? 0 : react_native_1.Dimensions.get('window').height,
            duration: 300,
            useNativeDriver: false,
        }).start(() => { });
    };
    return ((0, jsx_runtime_1.jsx)(react_native_1.Animated.View, { style: [styles.container, { bottom: height }], children: (0, jsx_runtime_1.jsx)(react_native_1.Pressable, { style: { flex: 1 }, onPress: () => props.onClose(), children: (0, jsx_runtime_1.jsxs)(react_native_1.ScrollView, { style: styles.overlay, children: [(0, jsx_runtime_1.jsxs)(react_native_1.Pressable, { style: styles.closeContainer, onPress: () => {
                            props.onClose();
                        }, children: [(0, jsx_runtime_1.jsx)(react_native_1.Text, { style: styles.buttonX, children: "\u00D7" }), (0, jsx_runtime_1.jsx)(react_native_1.Text, { style: styles.buttonText, children: props.selectedCategory?.name ?? 'すべて' })] }), (0, jsx_runtime_1.jsx)(react_native_1.View, { style: {
                            flexDirection: 'row',
                            marginTop: (0, scale_1.scale)(60),
                            flexWrap: 'wrap',
                            marginHorizontal: 10,
                        }, children: data.map((item, index) => {
                            return ((0, jsx_runtime_1.jsx)(react_native_1.Pressable, { style: {
                                    width: '100%',
                                    marginBottom: (0, scale_1.scale)(10),
                                    borderBottomColor: 'white',
                                    borderBottomWidth: 1,
                                }, onPress: () => props.onSelectData(item), children: (0, jsx_runtime_1.jsxs)(react_native_1.Text, { style: styles.item, children: ["\u30FB", item.name] }) }, index));
                        }) })] }) }) }));
};
exports.default = CategoryOverlayContainer;
const styles = react_native_1.StyleSheet.create({
    container: {
        position: "absolute",
        top: 0,
        right: 0,
        left: 0,
        zIndex: 2,
    },
    overlay: {
        position: "absolute",
        top: 0,
        right: (0, devices_1.isPhone)() ? 150 : 170,
        bottom: 0,
        left: 0,
        backgroundColor: "#CF7285",
    },
    closeContainer: {
        flexDirection: "row",
        position: "absolute",
        left: 10,
        top: (0, scale_1.scale)(10),
        justifyContent: "center",
        alignItems: "center",
    },
    buttonX: {
        color: "#FFFFFF",
        ...constants_1.FontFamily.NotoSerif700,
        fontSize: 24,
        marginBottom: 4,
    },
    buttonText: {
        color: "#FFFFFF",
        ...constants_1.FontFamily.NotoSerif700,
        fontSize: (0, scale_1.fontsize)(16, 20),
        marginHorizontal: 10,
    },
    item: {
        color: "#FFFFFF",
        ...constants_1.FontFamily.NotoSerif700,
        fontSize: (0, scale_1.fontsize)(16, 20),
        width: "80%",
        lineHeight: (0, scale_1.moderateScale)(24),
        paddingBottom: (0, scale_1.scale)(5),
    },
});

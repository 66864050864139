"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.seminarReducer = exports.setSeminarAction = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const initialState = {
    isLoading: false,
    data: null,
};
exports.setSeminarAction = (0, toolkit_1.createAction)('setSeminar');
const SeminarSlice = (0, toolkit_1.createSlice)({
    name: 'Seminar',
    initialState,
    reducers: {
        getSeminar: (state, action) => {
            return { ...state, action, isLoading: true };
        },
    },
    extraReducers: builder => {
        builder.addCase(exports.setSeminarAction, (state, action) => {
            return {
                ...state,
                data: action.payload,
                isLoading: false,
            };
        });
    },
});
exports.seminarReducer = SeminarSlice.reducer;
exports.default = SeminarSlice;

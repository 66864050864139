"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.warn = exports.log = void 0;
const log = (...params) => {
    if (process.env.NODE_ENV === "development") {
        // eslint-disable-next-line no-console
        console.log(...params);
    }
};
exports.log = log;
const warn = (...params) => {
    if (process.env.NODE_ENV === "development") {
        // eslint-disable-next-line no-console
        console.warn(...params);
    }
};
exports.warn = warn;

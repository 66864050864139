"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_native_svg_1 = __importStar(require("react-native-svg"));
const IconSeminar = (props) => ((0, jsx_runtime_1.jsxs)(react_native_svg_1.default, { xmlns: "http://www.w3.org/2000/svg", width: 24.868, height: 30, viewBox: "0 0 24.868 30", ...props, children: [(0, jsx_runtime_1.jsx)(react_native_svg_1.Path, { "data-name": "Path 123", d: "M16.718 12.429a4.284 4.284 0 1 1-4.284-4.284 4.284 4.284 0 0 1 4.284 4.284Z", fill: "#cf7285" }), (0, jsx_runtime_1.jsx)(react_native_svg_1.Path, { "data-name": "Path 124", d: "M24.868 12.434A12.434 12.434 0 1 0 9.025 24.391L12.434 30l3.409-5.609a12.437 12.437 0 0 0 9.025-11.957Zm-12.433 9.03a9.03 9.03 0 1 1 9.031-9.03 9.03 9.03 0 0 1-9.031 9.03Z", fill: "#cf7285" })] }));
exports.default = IconSeminar;

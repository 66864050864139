"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_native_svg_1 = __importStar(require("react-native-svg"));
const IconRight = (props) => ((0, jsx_runtime_1.jsx)(react_native_svg_1.default, { xmlns: "http://www.w3.org/2000/svg", width: 10.823, height: 16.857, viewBox: "0 0 10.823 16.857", ...props, children: (0, jsx_runtime_1.jsxs)(react_native_svg_1.G, { stroke: "#c8d1d3", strokeLinecap: "round", strokeWidth: 2, children: [(0, jsx_runtime_1.jsx)(react_native_svg_1.Path, { "data-name": "Line 67", fill: "none", d: "m1.411 15.446 8-6.981" }), (0, jsx_runtime_1.jsx)(react_native_svg_1.Path, { "data-name": "Path 111", d: "m1.49 1.412 7.921 7.035", fill: "#c8d1d3" })] }) }));
exports.default = IconRight;

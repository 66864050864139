"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fontCheck1 = exports.fontCheck = exports.isTabletDevices = exports.height = exports.width = exports.ratioW = exports.moderateScale = exports.verticalScale = exports.scale = exports.checkDeviceScreen = exports.DeviceWidth = exports.DeviceScreen = exports.fontsize = void 0;
const react_native_1 = require("react-native");
const react_native_device_info_1 = require("react-native-device-info");
const devices_1 = require("../constants/devices");
const { width, height } = react_native_1.Dimensions.get("window");
exports.width = width;
exports.height = height;
const [shortDimension, longDimension] = width < height ? [width, height] : [height, width];
const isTabletDevices = () => {
    if (react_native_1.Platform.OS === "android") {
        return longDimension / shortDimension <= 1.6;
    }
    return (0, react_native_device_info_1.isTablet)();
};
exports.isTabletDevices = isTabletDevices;
//Guideline sizes are based on standard ~5" screen mobile device and 7,9" screen tablet & ipad
const guidelineBaseHeight = isTabletDevices() ? 1024 : 667;
const scale = (size) => ((0, devices_1.isPhone)() ? size : size * 1.1);
exports.scale = scale;
const verticalScale = (size) => (longDimension / guidelineBaseHeight) * size;
exports.verticalScale = verticalScale;
const moderateScale = (size, factor = 0.5) => size + (scale(size) - size) * factor;
exports.moderateScale = moderateScale;
const ratioW = (size) => (shortDimension * size) / 100;
exports.ratioW = ratioW;
const fontCheck = (font) => {
    return isTabletDevices() ? moderateScale(font * 1.2) : scale(font);
};
exports.fontCheck = fontCheck;
const fontCheck1 = (font) => {
    return isTabletDevices() ? moderateScale(font * 1.2) : scale(font * 1.2);
};
exports.fontCheck1 = fontCheck1;
const fontsize = (phone, pad) => {
    return (0, devices_1.isPhone)() ? phone : pad;
};
exports.fontsize = fontsize;
exports.DeviceScreen = {
    MOBILE: "mobile",
    UL_MOBILE: "ultra_mobile",
    TABLET: "tablet",
    LAPTOP: "laptop",
    DESkTOP: "desktop",
    ULTRA_DESkTOP: "ul_desktop",
};
exports.DeviceWidth = {
    MOBILE: 320,
    UL_MOBILE: 450,
    TABLET: 768,
    LAPTOP: 1024,
    DESkTOP: 1200,
};
const checkDeviceScreen = (dynamicWidth) => {
    if (dynamicWidth <= exports.DeviceWidth.MOBILE) {
        return exports.DeviceScreen.MOBILE;
    }
    else if (dynamicWidth <= exports.DeviceWidth.UL_MOBILE) {
        return exports.DeviceScreen.UL_MOBILE;
    }
    else if (dynamicWidth <= exports.DeviceWidth.TABLET) {
        return exports.DeviceScreen.TABLET;
    }
    else if (dynamicWidth <= exports.DeviceWidth.LAPTOP) {
        // return DeviceScreen.LAPTOP;
        return exports.DeviceScreen.TABLET;
    }
    else if (dynamicWidth <= exports.DeviceWidth.DESkTOP) {
        return exports.DeviceScreen.DESkTOP;
    }
    else {
        return exports.DeviceScreen.ULTRA_DESkTOP;
    }
};
exports.checkDeviceScreen = checkDeviceScreen;

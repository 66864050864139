"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.detectPhoneSize = exports.isPhone = exports.isTablet = void 0;
const react_native_1 = require("react-native");
const isTablet = () => {
    const aspectRatio = react_native_1.Dimensions.get('window').width / react_native_1.Dimensions.get('window').height;
    if (react_native_1.Platform.OS == 'android' || react_native_1.Platform.OS == 'ios') {
        return aspectRatio < 1.6;
    }
    return aspectRatio < 1.6;
};
exports.isTablet = isTablet;
const isPhone = () => {
    const { width, height } = react_native_1.Dimensions.get('screen');
    if (react_native_1.Platform.OS == 'android' || react_native_1.Platform.OS == 'ios') {
        const aspectRatio = height / width;
        return aspectRatio >= 1.6;
    }
    return react_native_1.Dimensions.get('screen').width < 768;
};
exports.isPhone = isPhone;
const detectPhoneSize = (width, height) => {
    if (react_native_1.Platform.OS == 'android' || react_native_1.Platform.OS == 'ios') {
        const aspectRatio = height / width;
        return aspectRatio >= 1.6;
    }
    return width < 768;
};
exports.detectPhoneSize = detectPhoneSize;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_native_1 = require("react-native");
const constants_1 = require("../../../../constants");
exports.default = react_native_1.StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#FCF9F2",
    },
    headerContainer: {
        backgroundColor: "#F3F3F3",
        justifyContent: "flex-start",
        height: 70,
        width: "100%",
        position: "absolute",
        flexDirection: "row",
        alignItems: "center",
        top: 0,
        shadowColor: "#000",
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.2,
        marginBottom: 10,
        elevation: 2,
        zIndex: 100,
    },
    headerText: {
        color: "#CF7285",
        ...constants_1.FontFamily.NotoSerif700,
        fontSize: 20,
        marginLeft: 10,
        marginBottom: 4,
    },
    scrollContainer: {
        position: "absolute",
        top: 70,
        bottom: 0,
        width: "100%",
    },
    bodyContainer: {
        backgroundColor: "#FFFFFF",
        justifyContent: "center",
        height: 207,
        marginHorizontal: 25.8,
        borderRadius: 20,
        width: "86%",
        marginLeft: "7%",
    },
    rowContainer: {
        justifyContent: "space-between",
        flexDirection: "row",
        alignItems: "center",
        flex: 1,
    },
    itemText: {
        color: "#334856",
        ...constants_1.FontFamily.NotoSerif300,
        fontSize: 15,
        marginLeft: 30,
        width: 200,
    },
    footerContainer: {
        justifyContent: "center",
        alignItems: "center",
        marginBottom: 30,
    },
    changePasswordContainer: {
        justifyContent: "center",
        alignItems: "center",
        borderColor: "#334856",
        borderWidth: 0.5,
        borderRadius: 25,
        height: 50,
        width: 200,
        marginTop: 40,
    },
    transferContainer: {
        justifyContent: "center",
        alignItems: "center",
        borderColor: "#334856",
        borderWidth: 0.5,
        borderRadius: 25,
        height: 50,
        width: 200,
        marginTop: 30,
    },
    fotterButtonText: {
        color: "#334856",
        ...constants_1.FontFamily.NotoSerif300,
        fontSize: 12,
    },
    changePasswordText: {
        color: "#334856",
        ...constants_1.FontFamily.NotoSerif300,
        fontSize: 14,
    },
});

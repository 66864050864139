"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_native_1 = require("react-native");
const scale_1 = require("../../../../utils/scale");
const constants_1 = require("../../../../constants");
exports.default = react_native_1.StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "#FCF9F2",
    },
    headerContainer: {
        backgroundColor: "#F3F3F3",
        justifyContent: "center",
        height: 70,
        width: "100%",
        shadowColor: "#000",
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.2,
        marginBottom: 10,
        elevation: 2,
    },
    headerText: {
        color: "#CF7285",
        ...constants_1.FontFamily.NotoSerif700,
        fontSize: 20,
        marginLeft: 40,
    },
    headerButtonContainer: {
        flexDirection: "row",
        backgroundColor: "#F3F3F3",
        height: 57,
        width: "100%",
    },
    therapistContainer: {
        backgroundColor: "#FCF9F2",
        justifyContent: "space-between",
        paddingBottom: 30,
    },
    therapistPreview: {
        flexDirection: "column",
        backgroundColor: "white",
        shadowColor: "#505C62",
        borderRadius: 10,
        marginTop: 20,
        marginHorizontal: 20,
        shadowOffset: { width: 1, height: 1 },
        shadowOpacity: 0.4,
        shadowRadius: 3,
        elevation: 5,
        overflow: "hidden",
    },
    therapistTextContainer: {
        flexDirection: "column",
        overflow: "hidden",
        flex: 1,
    },
    therapistText: {
        color: "#1B1C20",
        ...constants_1.FontFamily.NotoSerif700,
        fontSize: 16,
        marginTop: 20,
    },
    descriptionText: {
        color: "#1B1C20",
        ...constants_1.FontFamily.NotoSerif700,
        fontSize: 10,
        marginTop: 10,
        marginRight: 20,
    },
    tagContainer: {
        backgroundColor: "#F2A78D",
        height: 24,
        borderRadius: 12,
        alignItems: "center",
        justifyContent: "center",
        marginHorizontal: 2,
        marginVertical: 5,
    },
    tagText: {
        color: "#FFFFFF",
        ...constants_1.FontFamily.NotoSerif700,
        fontSize: 10,
        marginHorizontal: 10,
    },
    buttonContainer: {
        backgroundColor: "#E5E5E5",
        justifyContent: "center",
        height: 57,
        flexDirection: "row",
        alignItems: "center",
    },
    buttonText: {
        color: "#FFFFFF",
        ...constants_1.FontFamily.NotoSerif700,
        fontSize: 16,
        marginHorizontal: 10,
        position: "absolute",
        left: 54,
        right: 10,
    },
    buttonFilterText: {
        color: "#A5A5A5",
        ...constants_1.FontFamily.NotoSerif700,
        fontSize: (0, scale_1.moderateScale)(16),
        marginLeft: 8,
    },
    buttonFilterTextSelected: {
        color: "#000000",
        ...constants_1.FontFamily.NotoSerif700,
        fontSize: (0, scale_1.moderateScale)(16),
        marginLeft: 8,
    },
    buttonX: {
        color: "#000000",
        fontSize: 30,
        fontWeight: "bold",
        width: 18,
        ...react_native_1.Platform.select({
            android: {
                textAlignVertical: "center",
            },
            ios: { marginBottom: 2 },
            web: { marginBottom: 5 },
        }),
    },
});

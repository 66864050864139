"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_native_svg_1 = __importStar(require("react-native-svg"));
const IconLeft = (props) => ((0, jsx_runtime_1.jsx)(react_native_svg_1.default, { width: props.width, height: props.height, viewBox: "0 0 15 15", children: (0, jsx_runtime_1.jsxs)(react_native_svg_1.G, { id: "Group_80", "data-name": "Group 80", transform: "translate(1104 100) rotate(180)", children: [(0, jsx_runtime_1.jsx)(react_native_svg_1.Path, { id: "Path_2679", "data-name": "Path 2679", d: "M0,0H15V15H0Z", transform: "translate(1089 85)", fill: "rgba(255,255,255,0)" }), (0, jsx_runtime_1.jsxs)(react_native_svg_1.G, { id: "\u77E2\u5370", transform: "translate(1089 85)", children: [(0, jsx_runtime_1.jsx)(react_native_svg_1.Path, { id: "Path_2", "data-name": "Path 2", d: "M0,0H15V15H0Z", fill: "none" }), (0, jsx_runtime_1.jsx)(react_native_svg_1.Path, { id: "Path_3", "data-name": "Path 3", d: "M9.5,6l-.907.907,2.945,2.951L8.59,12.81l.907.907,3.858-3.858Z", transform: "translate(-3.29 -2.358)", fill: props.color })] })] }) }));
exports.default = IconLeft;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_native_1 = require("react-native");
const scale_1 = require("../../../utils/scale");
const constants_1 = require("../../../constants");
exports.default = react_native_1.StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: "#FCF9F2",
    },
    notificationContainer: {
        justifyContent: "center",
        alignItems: "center",
    },
    headerContainer: {
        justifyContent: "flex-start",
        alignItems: "center",
        marginLeft: 24,
        flexDirection: "row",
        marginTop: 20,
    },
    headerText: {
        color: "#1B1C20",
        ...constants_1.FontFamily.NotoSerif700,
        fontSize: (0, scale_1.moderateScale)(20),
        marginLeft: 8,
    },
    notificationListContainer: {
        justifyContent: "center",
    },
    viewAllButtonContainer: {
        backgroundColor: "#FFFFFF",
        paddingHorizontal: 20,
        paddingVertical: 10,
        borderRadius: 26,
        marginTop: 5,
        justifyContent: "center",
        alignItems: "center",
    },
    viewAllButton: {
        ...constants_1.FontFamily.NotoSerif700,
        fontSize: 15,
    },
    loadingIndicator: {
        position: "absolute",
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundColor: "#F5FCFF88",
        alignItems: "center",
        justifyContent: "center",
    },
});

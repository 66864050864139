"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_native_1 = require("react-native");
const scale_1 = require("../../utils/scale");
const devices_1 = require("../../constants/devices");
const constants_1 = require("../../constants");
const SearchBar = (props) => {
    const [searchPhrase, setSearchPhrase] = (0, react_1.useState)(props?.keyword);
    const [clicked, setClicked] = (0, react_1.useState)(false);
    return ((0, jsx_runtime_1.jsxs)(react_native_1.View, { style: styles.container, children: [(0, jsx_runtime_1.jsx)(react_native_1.View, { style: styles.searchBar__unclicked, children: (0, jsx_runtime_1.jsx)(react_native_1.TextInput, { style: styles.input, placeholder: props.placeholder, placeholderTextColor: "#A5A5A5", value: searchPhrase, onChangeText: setSearchPhrase, onFocus: () => {
                        setClicked(true);
                    } }) }), (0, jsx_runtime_1.jsx)(react_native_1.TouchableOpacity, { onPress: () => {
                    react_native_1.Keyboard.dismiss();
                    setSearchPhrase("");
                    setClicked(false);
                }, children: (0, jsx_runtime_1.jsx)(react_native_1.Text, { style: { color: "#A5A5A5", paddingRight: 10 }, children: "\u00D7" }) }), (0, jsx_runtime_1.jsx)(react_native_1.TouchableOpacity, { style: { height: (0, scale_1.scale)(30), justifyContent: 'center' }, onPress: () => {
                    react_native_1.Keyboard.dismiss();
                    setClicked(false);
                    props.onSearch(searchPhrase);
                }, children: (0, jsx_runtime_1.jsx)(react_native_1.Text, { style: { color: "#A5A5A5", paddingRight: (0, devices_1.isPhone)() ? 30 : (0, scale_1.scale)(100) }, children: "\uD83D\uDD0D" }) })] }));
};
exports.default = SearchBar;
// styles
const styles = react_native_1.StyleSheet.create({
    container: {
        margin: 15,
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row",
        height: (0, scale_1.scale)(30),
        backgroundColor: "white",
        borderColor: "#A5A5A5",
        borderWidth: 1,
        borderRadius: (0, scale_1.scale)(15),
        width: "80%",
        flex: 1,
    },
    searchBar__unclicked: {
        flexDirection: "row",
        width: "90%",
        paddingLeft: (0, scale_1.scale)(10),
        height: (0, scale_1.scale)(30),
        alignItems: "center",
    },
    searchBar__clicked: {
        flexDirection: "row",
        width: "90%",
        paddingLeft: (0, scale_1.scale)(10),
        height: (0, scale_1.scale)(30),
        alignItems: "center",
        justifyContent: "space-evenly",
    },
    input: {
        fontSize: 14,
        width: "100%",
        backgroundColor: "transparent",
        ...react_native_1.Platform.select({
            web: {
                outlineStyle: "none",
            },
        }),
        includeFontPadding: false,
        ...constants_1.FontFamily.NotoSerif600,
        padding: 0,
        paddingLeft: (0, scale_1.scale)(30),
        marginLeft: (0, devices_1.isPhone)() ? 0 : (0, scale_1.scale)(30),
    },
});

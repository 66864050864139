"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_native_1 = require("react-native");
const scale_1 = require("../../../utils/scale");
const lodash_1 = require("lodash");
const constants_1 = require("../../../constants");
exports.default = react_native_1.StyleSheet.create({
    container: {
        alignItems: "center",
        justifyContent: "space-between",
        position: "absolute",
        bottom: 60,
        flex: 1,
    },
    loginForm: {
        justifyContent: "center",
    },
    topIntroText: {
        width: "100%",
        textAlign: "center",
        color: "#0E0E0E",
        ...constants_1.FontFamily.NotoSerif700,
        fontSize: 14,
        marginBottom: (0, scale_1.scale)(13),
        alignSelf: "center",
        marginTop: 20,
    },
    introText: {
        paddingTop: 70,
        paddingBottom: 40,
        width: "100%",
        textAlign: "center",
        color: "#D68F9D",
        ...constants_1.FontFamily.NotoSerif,
        fontSize: 18,
    },
    inputContainer: {
        height: (0, scale_1.scale)(52),
        width: (0, lodash_1.min)([400, react_native_1.Dimensions.get("window").width * 0.7]),
        borderRadius: (0, scale_1.scale)(20),
        backgroundColor: "#A5A5A5",
        marginTop: react_native_1.Platform.OS == "android" ? 0 : 10,
        marginHorizontal: 40,
        flexDirection: "row",
    },
    input: {
        marginHorizontal: 20,
        color: "white",
        padding: 0,
        includeFontPadding: false,
        ...constants_1.FontFamily.NotoSerif,
        ...react_native_1.Platform.select({
            web: {
                outlineStyle: "none",
            },
        }),
        flex: 1,
    },
    buttonContainer: {
        width: (0, lodash_1.min)([400, react_native_1.Dimensions.get("window").width * 0.7]),
        justifyContent: "center",
        marginTop: 35,
        marginHorizontal: 40,
        alignItems: "center",
        height: (0, scale_1.scale)(40),
        borderRadius: (0, scale_1.scale)(20),
        marginBottom: 20,
    },
    imageContainer: {
        position: "absolute",
        resizeMode: "contain",
        top: 40,
        left: 0,
        height: "30%",
        width: "100%",
    },
    loginAccountText: {
        color: "#0E0E0E",
        ...constants_1.FontFamily.NotoSerif700,
        fontSize: (0, scale_1.moderateScale)(24),
        textAlign: "center",
    },
    passwordText: {
        color: "#0E0E0E",
        ...constants_1.FontFamily.NotoSerif700,
        fontSize: 13,
        textAlign: "left",
        marginHorizontal: (0, scale_1.scale)(50),
        marginBottom: 10,
    },
    err: {
        width: (0, lodash_1.min)([400, react_native_1.Dimensions.get("window").width * 0.7]),
        color: "#FF0000",
        fontSize: (0, scale_1.moderateScale)(13),
        marginVertical: (0, scale_1.scale)(10),
        marginLeft: 40,
    },
    loadingIndicator: {
        position: "absolute",
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundColor: "#F5FCFF88",
        alignItems: "center",
        justifyContent: "center",
    },
});

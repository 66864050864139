"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_native_1 = require("react-native");
const MediumText = (props) => {
    const { children, style } = props;
    return ((0, jsx_runtime_1.jsx)(react_native_1.Text, { ...props, style: [styles.text, style], children: children }));
};
const styles = react_native_1.StyleSheet.create({
    text: {
        fontSize: 15,
        // lineHeight: scale(20),
        fontWeight: "500"
    },
});
exports.default = MediumText;

"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_native_svg_1 = __importStar(require("react-native-svg"));
const IconPolicy = (props) => ((0, jsx_runtime_1.jsxs)(react_native_svg_1.default, { xmlns: "http://www.w3.org/2000/svg", width: 74.447, height: 93.209, viewBox: "0 0 74.447 93.209", ...props, children: [(0, jsx_runtime_1.jsx)(react_native_svg_1.Path, { id: "Path_206", "data-name": "Path 206", d: "M5311.292-176.179a2.131,2.131,0,0,0-2.986.4l-19.768,25.843-13.262-13.156a2.13,2.13,0,0,0-3.012.013,2.13,2.13,0,0,0,.012,3.012l14.265,14.151a3.025,3.025,0,0,0,2.126.875c.069,0,.138,0,.207-.007a3.018,3.018,0,0,0,2.192-1.178l20.625-26.964A2.13,2.13,0,0,0,5311.292-176.179Z", transform: "translate(-5254.663 207.375)", fill: props.colors ?? '#fff' }), (0, jsx_runtime_1.jsx)(react_native_svg_1.Path, { id: "Path_207", "data-name": "Path 207", d: "M5318.66-204.574a18.244,18.244,0,0,1-16.325-16.328l-.189-1.921h-37.573l-.189,1.921a18.245,18.245,0,0,1-16.328,16.328l-1.92.189v24.346a51.271,51.271,0,0,0,9.778,30.416,54.1,54.1,0,0,0,26.759,19.776l.686.233.687-.233a54.1,54.1,0,0,0,26.759-19.776,51.264,51.264,0,0,0,9.778-30.416v-24.346Zm-2.337,24.535a47.039,47.039,0,0,1-8.963,27.907,49.888,49.888,0,0,1-24,18.013,49.889,49.889,0,0,1-24-18.013,47.032,47.032,0,0,1-8.961-27.907v-20.569a22.534,22.534,0,0,0,17.954-17.954h30.019a22.535,22.535,0,0,0,17.956,17.954Z", transform: "translate(-5246.134 222.823)", fill: props.colors ?? '#fff' })] }));
exports.default = IconPolicy;

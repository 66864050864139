"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_native_1 = require("react-native");
const scale_1 = require("../../../../utils/scale");
const constants_1 = require("../../../../constants");
exports.default = react_native_1.StyleSheet.create({
    container: {
        flex: 1,
        alignItems: "center",
        backgroundColor: "#FCF9F2",
    },
    headerContainer: {
        backgroundColor: "#F3F3F3",
        justifyContent: "flex-start",
        height: 70,
        width: "100%",
        position: "absolute",
        flexDirection: "row",
        alignItems: "center",
        top: 0,
        shadowColor: "#000",
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.2,
        marginBottom: 10,
        elevation: 2,
        zIndex: 10,
    },
    headerText: {
        color: "#CF7285",
        ...constants_1.FontFamily.NotoSerif600,
        fontSize: 20,
        marginLeft: 10,
        alignSelf: "center",
        marginBottom: 4,
    },
    scrollContainer: {
        position: "absolute",
        top: 70,
        bottom: 0,
        width: "100%",
    },
    textSuccess: {
        ...constants_1.FontFamily.NotoSerif,
        fontSize: 16,
        color: "#0E0E0E",
        textAlign: "center",
        marginTop: (0, scale_1.scale)(90),
    },
    viewTextInput: {
        marginHorizontal: (0, scale_1.scale)(20),
        borderLeftWidth: (0, scale_1.scale)(4),
        borderLeftColor: "#CF7285",
        height: (0, scale_1.scale)(29),
        justifyContent: "center",
        marginTop: (0, scale_1.scale)(20),
    },
    inputText: {
        color: "black",
        ...constants_1.FontFamily.NotoSerif300,
        fontSize: (0, scale_1.moderateScale)(14),
        marginHorizontal: (0, scale_1.scale)(15),
        lineHeight: (0, scale_1.moderateScale)(20),
    },
    input: {
        height: 53,
        marginHorizontal: 20,
        borderRadius: 10,
        marginTop: (0, scale_1.scale)(10),
        includeFontPadding: false,
        color: "black",
        paddingHorizontal: 20,
        ...constants_1.FontFamily.NotoSerif,
        ...react_native_1.Platform.select({
            web: {
                outlineStyle: "none",
            },
        }),
    },
    inputArea: {
        height: 200,
        marginHorizontal: 20,
        borderRadius: 10,
        marginTop: (0, scale_1.scale)(10),
        includeFontPadding: false,
        color: "black",
        paddingHorizontal: 20,
        paddingVertical: (0, scale_1.scale)(15),
        padding: 0,
        ...constants_1.FontFamily.NotoSerif,
        textAlignVertical: "top",
        ...react_native_1.Platform.select({
            web: {
                outlineStyle: "none",
            },
        }),
    },
    buttonContainer: {
        width: "80%",
        height: 52,
        marginHorizontal: 20,
        justifyContent: "center",
        marginBottom: 30,
        alignItems: "center",
        borderRadius: 26,
        marginTop: (0, scale_1.scale)(20),
        alignSelf: "center",
    },
    loadingIndicator: {
        position: "absolute",
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundColor: "#F5FCFF88",
        alignItems: "center",
        justifyContent: "center",
    },
    err: {
        marginHorizontal: 30,
        color: "#FF0000",
        fontSize: (0, scale_1.moderateScale)(13),
    },
    line: {
        height: 2,
        backgroundColor: "#E1E1E1",
        marginHorizontal: 20,
        marginTop: (0, scale_1.scale)(18),
    },
});

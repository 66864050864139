"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_DATA_HOME_FAILED = exports.GET_DATA_HOME_SUCCESS = exports.GET_DATA_HOME = exports.SCROLL_TO_TOP = exports.TOGGLE_MENU = void 0;
exports.TOGGLE_MENU = 'TOGGLE_MENU';
exports.SCROLL_TO_TOP = 'SCROLL_TO_TOP';
exports.GET_DATA_HOME = 'GET_DATA_HOME';
exports.GET_DATA_HOME_SUCCESS = 'GET_DATA_HOME_SUCCESS';
exports.GET_DATA_HOME_FAILED = 'GET_DATA_HOME_FAILED';
const getDataHome = (callback) => {
    return {
        type: exports.GET_DATA_HOME,
        callback,
    };
};
const HomeActions = {
    getDataHome,
};
exports.default = HomeActions;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.profileReducer = exports.setUpdateProfileErrorAction = exports.setProfileAction = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const initialState = {
    isLoading: false,
    data: null,
    updateProfileErrorData: null,
    updateProfileSucess: false,
    noti: 0,
};
// create your action
exports.setProfileAction = (0, toolkit_1.createAction)('setProfile');
exports.setUpdateProfileErrorAction = (0, toolkit_1.createAction)('setUpdateProfileErrorAction');
const profileSlice = (0, toolkit_1.createSlice)({
    name: 'profile',
    initialState,
    reducers: {
        getProfile: state => {
            return state;
        },
    },
    // child action of profile
    extraReducers: builder => {
        builder.addCase(exports.setProfileAction, (state, action) => {
            return {
                ...state,
                data: action.payload,
            };
        });
        builder.addCase(exports.setUpdateProfileErrorAction, (state, action) => {
            return {
                ...state,
                updateProfileErrorData: action.payload,
            };
        });
    },
});
exports.profileReducer = profileSlice.reducer;
exports.default = profileSlice;

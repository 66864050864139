"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_native_svg_1 = __importStar(require("react-native-svg"));
const IconFAQ = (props) => ((0, jsx_runtime_1.jsx)(react_native_svg_1.default, { xmlns: "http://www.w3.org/2000/svg", width: 108.485, height: 81.044, viewBox: "0 0 108.485 81.044", ...props, children: (0, jsx_runtime_1.jsxs)(react_native_svg_1.G, { "data-name": "Group 203", fill: "#eeb1bd", children: [(0, jsx_runtime_1.jsx)(react_native_svg_1.Path, { "data-name": "Path 191", d: "M92.243 10.089C82.061 3.583 68.566 0 54.243 0s-27.818 3.583-38 10.089C5.768 16.782 0 25.78 0 35.422c0 8.271 4.372 16.231 12.348 22.55l-7.15 23.072 23.157-14.469a78.5 78.5 0 0 0 25.888 4.269c14.323 0 27.818-3.583 38-10.089 10.474-6.693 16.242-15.69 16.242-25.333s-5.768-18.639-16.242-25.333Zm-38 56.54a73.793 73.793 0 0 1-25.4-4.361l-.972-.36-15.289 9.553 4.671-15.074-1.2-.889C8.422 49.857 4.219 42.728 4.219 35.423c0-17.207 22.442-31.207 50.027-31.207s50.027 14 50.027 31.207-22.445 31.206-50.03 31.206Z" }), (0, jsx_runtime_1.jsx)(react_native_svg_1.Path, { "data-name": "Path 192", d: "M26.293 27.801a7.621 7.621 0 1 0 7.59 7.621 7.615 7.615 0 0 0-7.59-7.621Zm0 11.027a3.405 3.405 0 1 1 3.375-3.405 3.394 3.394 0 0 1-3.375 3.405Z" }), (0, jsx_runtime_1.jsx)(react_native_svg_1.Path, { "data-name": "Path 193", d: "M54.242 27.801a7.621 7.621 0 1 0 7.591 7.621 7.614 7.614 0 0 0-7.591-7.621Zm0 11.027a3.405 3.405 0 1 1 3.375-3.405 3.394 3.394 0 0 1-3.375 3.405Z" }), (0, jsx_runtime_1.jsx)(react_native_svg_1.Path, { "data-name": "Path 194", d: "M82.193 27.801a7.621 7.621 0 1 0 7.59 7.621 7.615 7.615 0 0 0-7.59-7.621Zm0 11.027a3.405 3.405 0 1 1 3.375-3.405 3.394 3.394 0 0 1-3.375 3.405Z" })] }) }));
exports.default = IconFAQ;

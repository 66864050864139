"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const Colors = {
    black: 'black',
    white: 'white',
    blue: 'blue',
    gray: '#F0F0F0',
    pink: '#F65B9A',
    primaryColor: '#144B85',
    backgroundColor: '#FFFFFF',
    grayOpacity40: '#07283D66',
    grayOpacity80: 'rgba(7, 40, 61, 0.8)',
    primaryRed: '#F1194D',
    redRequire: '#F80F63',
    textHeader: '#07283D',
    textHeaderOpacity10: '#07283D1A',
    textHeaderOpacity20: '#07283D33',
    textHeaderOpacity40: '#07283D66',
    textHeaderOpacity60: 'rgba(7, 40, 61, 0.6)',
    backgroundItem: '#F8FAFE',
    backgroundButton: '#F6F6F6',
    gray30: 'rgba(154, 154, 170, 0.3)',
    gray10: 'rgba(154, 154, 170, 0.1)',
    white60: 'rgba(255, 255, 255, 0.6)',
    secondaryColor: '#81B3E8',
    blueStrong: '#004EA1',
    blueStrong1: '#106F81',
    blueLight: '#F1F8FF',
    grayLight: '#F7F7F7',
    green: '#31AC3E',
    tertiarуGreen15: 'rgba(17, 166, 112, 0.15)',
    tertiarуGreen: 'rgba(17, 166, 112, 1)',
    tertiaryGray5: 'rgba(7, 40, 61, 0.5)',
    tertiaryGray13: 'rgba(7, 40, 61, 0.13)',
    tertiaryBlack: 'rgba(7, 40, 61, 1)',
    tertiaryBlue: '#4BB4D6',
    tertiaryPink: '#F371AA',
    tertiaryPuple: '#6B81FC',
    tertiaryGray: '#F7F7F7',
    tertiaryOrange: '#F58722',
    tertiaryCyan: '#4CB5FD',
    tertiaryGray4: 'rgba(7, 40, 61, 0.4)',
    tabNewsBackgroundColor: '#F7F7F7',
    textNewsAllColor: '#4CB5FD',
    gradient1: 'rgba(26, 172, 199, 0.36)',
    gradient2: '#118FA6',
    lastWeek: '#F8CFB3',
    transparent: 'transparent',
    tooltipBackground: '#D1EBF0',
    chatIconDefaultColor: '#9CA9B1',
    loadingLogin: '#F5FCFF88',
    textBirthday: '#F85776',
    gray1: '#DDDDDD',
    gray2: '#CCCCCC',
    gray3: '#BBBBBB',
    gray4: '#9D9D9D',
    gray5: '#EEEEEE',
    orange: '#D48D23',
    orange1: '#FBFCFF',
    blue1: '#3386CE',
    blue2: '#266AA5',
    blue3: '#F2FFFD',
    white1: '#F9F9F9',
    white2: '#FEFEFE',
    white3: '#FAFBFF',
    white4: '#F7F9FE',
    black1: '#333333',
    black2: '#666666',
};
exports.default = Colors;

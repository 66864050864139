"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function makeActionCreator(type, ...argNames) {
    return function (...args) {
        const action = { type };
        argNames.forEach((_arg, index) => {
            action[argNames[index]] = args[index];
        });
        return action;
    };
}
exports.default = makeActionCreator;

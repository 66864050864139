"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_native_1 = require("react-native");
const scale_1 = require("../../../utils/scale");
const lodash_1 = require("lodash");
exports.default = react_native_1.StyleSheet.create({
    container: {
        alignItems: 'center',
        // justifyContent: 'space-between',
        // position: 'absolute',
        marginTop: (0, scale_1.scale)(20),
    },
    loginForm: {
        justifyContent: 'center',
        paddingHorizontal: 20,
    },
    input: {
        height: (0, scale_1.scale)(52),
        width: (0, lodash_1.min)([400, react_native_1.Dimensions.get('window').width * 0.7]),
        borderRadius: (0, scale_1.scale)(26),
        backgroundColor: '#A5A5A5',
        color: 'white',
        alignItems: 'center',
        includeFontPadding: false,
        fontFamily: 'Noto Serif JP',
        padding: 0,
        fontSize: (0, scale_1.moderateScale)(14),
        ...react_native_1.Platform.select({
            web: {
                outlineStyle: 'none',
            },
        }),
        marginVertical: 10,
        marginHorizontal: 20,
        paddingHorizontal: 20,
    },
    buttonContainer: {
        height: (0, scale_1.scale)(52),
        width: (0, lodash_1.min)([400, react_native_1.Dimensions.get('window').width * 0.7]),
        justifyContent: 'center',
        marginBottom: (0, scale_1.scale)(55),
        marginTop: (0, scale_1.scale)(20),
        alignItems: 'center',
        borderRadius: (0, scale_1.scale)(26),
    },
    button: {
        alignItems: 'center',
        justifyContent: 'center',
        height: (0, scale_1.scale)(40),
        width: (0, lodash_1.min)([400, react_native_1.Dimensions.get('window').width * 0.7]),
        borderRadius: (0, scale_1.scale)(20),
        marginTop: (0, scale_1.scale)(30),
        marginHorizontal: (0, scale_1.scale)(20),
    },
    introText: {
        marginVertical: 30,
        marginHorizontal: 40,
        fontWeight: 'bold',
        textAlign: 'center',
        color: '#0E0E0E',
        fontFamily: 'Noto Serif JP',
        fontSize: 14,
    },
    textInput: {
        marginHorizontal: 10,
        marginVertical: react_native_1.Platform.OS === 'android' ? 0 : 0,
        fontWeight: 'bold',
        textAlign: 'left',
        color: '#0E0E0E',
        fontFamily: 'Noto Serif JP',
        fontSize: (0, scale_1.moderateScale)(13),
        marginStart: 35,
    },
    headerText: {
        color: '#0E0E0E',
        fontWeight: '700',
        marginTop: (0, scale_1.scale)(30),
        fontFamily: 'Noto Serif JP',
        fontSize: 24,
        textAlign: 'center',
    },
    loadingIndicator: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundColor: '#F5FCFF88',
        alignItems: 'center',
        justifyContent: 'center',
    },
    err: {
        color: '#FF0000',
        fontSize: (0, scale_1.moderateScale)(13),
        marginHorizontal: 20,
        marginBottom: 10,
    },
    viewContain: {
        flex: 1,
        backgroundColor: '#101C2D66',
        alignItems: 'center',
        justifyContent: 'center',
        paddingHorizontal: (0, scale_1.scale)(20),
    },
    viewModal: {
        backgroundColor: '#FFFFFF',
        alignItems: 'center',
        borderRadius: (0, scale_1.scale)(8),
        paddingVertical: (0, scale_1.scale)(20),
    },
    textTitle: {
        marginTop: (0, scale_1.scale)(0),
        marginBottom: (0, scale_1.scale)(10),
        color: '#000000',
        fontSize: 24,
        lineHeight: (0, scale_1.moderateScale)(40),
        fontFamily: 'Noto Serif JP',
        fontWeight: react_native_1.Platform.OS === 'ios' ? '700' : 'bold',
    },
    textTitle1: {
        fontSize: 14,
        lineHeight: (0, scale_1.moderateScale)(22),
        color: '#0E0E0E',
        fontFamily: 'Noto Serif JP',
        fontWeight: 'bold',
    },
});

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.signupReducer = exports.setSignupSuccessAction = exports.setSignupErrorAction = exports.setVerifyPhoneNumberSuccessAction = exports.setVerifyPhoneNumberErrorAction = exports.setVerifyErrorAction = exports.setVerifySuccessAction = exports.setRegisterConfirmErrorAction = exports.setRegisterConfirmSuccessAction = exports.setPreUserDataErrorAction = exports.setPreUserSuccessAction = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const initialState = {
    isLoading: false,
    preUserSuccess: false,
    preUserDataError: null,
    registerConfirmSuccessData: null,
    registerConfirmErrorData: null,
    verifySuccessData: null,
    verifyErrorData: null,
    verifyPhoneSuccessData: null,
    verifyPhoneErrorData: null,
    signupSuccessData: null,
    signupErrorData: null,
};
// create your action
exports.setPreUserSuccessAction = (0, toolkit_1.createAction)('setPreUserSuccessAction');
exports.setPreUserDataErrorAction = (0, toolkit_1.createAction)('setPreUserDataErrorAction');
exports.setRegisterConfirmSuccessAction = (0, toolkit_1.createAction)('setRegisterConfirmSuccessAction');
exports.setRegisterConfirmErrorAction = (0, toolkit_1.createAction)('setRegisterConfirmErrorAction');
exports.setVerifySuccessAction = (0, toolkit_1.createAction)('setVerifySuccessAction');
exports.setVerifyErrorAction = (0, toolkit_1.createAction)('setVerifyErrorAction');
exports.setVerifyPhoneNumberErrorAction = (0, toolkit_1.createAction)('setVerifyPhoneNumberErrorAction');
exports.setVerifyPhoneNumberSuccessAction = (0, toolkit_1.createAction)('setVerifyPhoneNumberSuccessAction');
exports.setSignupErrorAction = (0, toolkit_1.createAction)('setSignupErrorAction');
exports.setSignupSuccessAction = (0, toolkit_1.createAction)('setSignupSuccessAction');
const SignupSlice = (0, toolkit_1.createSlice)({
    name: 'signup',
    initialState,
    reducers: {
        reset: () => {
            return {
                isLoading: false,
                preUserSuccess: false,
                preUserDataError: null,
                registerConfirmSuccessData: null,
                registerConfirmErrorData: null,
                verifySuccessData: null,
                verifyErrorData: null,
                verifyPhoneSuccessData: null,
                verifyPhoneErrorData: null,
                signupSuccessData: null,
                signupErrorData: null,
            };
        },
        preUser: (state, action) => {
            return {
                ...state,
                action,
                isLoading: true,
                preUserDataError: '',
                preUserSuccess: false,
            };
        },
        registerConfirm: (state, action) => {
            return { ...state, action, isLoading: true };
        },
        verifyOTP: (state, action) => {
            return { ...state, action, isLoading: true };
        },
        verifyPhone: (state, action) => {
            return {
                ...state,
                action,
                isLoading: true,
                verifyPhoneSuccessData: null,
                verifyPhoneErrorData: null,
            };
        },
        signup: (state, action) => {
            return { ...state, action, isLoading: true };
        },
    },
    // child action of profile
    extraReducers: builder => {
        builder.addCase(exports.setPreUserSuccessAction, (state, action) => {
            return {
                ...state,
                preUserSuccess: action.payload,
                isLoading: false,
            };
        });
        builder.addCase(exports.setPreUserDataErrorAction, (state, action) => {
            return {
                ...state,
                preUserDataError: action.payload,
                isLoading: false,
            };
        });
        builder.addCase(exports.setRegisterConfirmSuccessAction, (state, action) => {
            return {
                ...state,
                registerConfirmSuccessData: action.payload,
                isLoading: false,
            };
        });
        builder.addCase(exports.setRegisterConfirmErrorAction, (state, action) => {
            return {
                ...state,
                registerConfirmErrorData: action.payload,
                isLoading: false,
            };
        });
        builder.addCase(exports.setVerifySuccessAction, (state, action) => {
            return {
                ...state,
                verifySuccessData: action.payload,
                isLoading: false,
            };
        });
        builder.addCase(exports.setVerifyErrorAction, (state, action) => {
            return {
                ...state,
                verifyErrorData: action.payload,
                isLoading: false,
            };
        });
        builder.addCase(exports.setVerifyPhoneNumberSuccessAction, (state, action) => {
            return {
                ...state,
                verifyPhoneSuccessData: action.payload,
                isLoading: false,
            };
        });
        builder.addCase(exports.setVerifyPhoneNumberErrorAction, (state, action) => {
            return {
                ...state,
                verifyPhoneErrorData: action.payload,
                isLoading: false,
            };
        });
        builder.addCase(exports.setSignupSuccessAction, (state, action) => {
            return {
                ...state,
                signupSuccessData: action.payload,
                isLoading: false,
            };
        });
        builder.addCase(exports.setSignupErrorAction, (state, action) => {
            return {
                ...state,
                signupErrorData: action.payload,
                isLoading: false,
            };
        });
    },
});
exports.signupReducer = SignupSlice.reducer;
exports.default = SignupSlice;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.images = void 0;
exports.images = {
    BackgroundImage: require('./background_image.png'),
    BackgroundImageIpad: require('./background_image_ipad.png'),
    LogoImage: require('./ic_logo.png'),
    BackgroundButton: require('./button_background.png'),
    MEMBER_ICON: require('./ic_member.png'),
    THERAPY_ICON: require('./ic_therapy.png'),
    THERAPIST_ICON: require('./ic_therapist.png'),
    SEMINAR_ICON: require('./ic_seminar.png'),
    GUIDE_ICON: require('./ic_guide.png'),
    HEADER_LOGO: require('./ic_header_logo.png'),
    TEMP_IMAGE: require('./temp_image.png'),
    CATEGORY_IMAGE: require('./ic_pickup.png'),
    IC_HAMBURGER: require('./ic_hamburger.png'),
    IC_THERAPIST_DETAIL: require('./ic_therapist_detail.png'),
};

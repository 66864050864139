"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.loginReducer = exports.setUpdateProfileErrorAction = exports.setProfileAction = exports.setErrorMessageAction = exports.setErrorsAction = exports.setLoginStateAction = exports.saveAccessTokenAction = exports.LoginState = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
exports.LoginState = {
    IS_UPDATE_INFO: 'IS_UPDATE_INFO',
    IS_LOGGED: 'IS_LOGGED',
    IS_NEED_LOGIN: 'IS_NEED_LOGIN',
    FIRST_SIGN_IN: 'FIRST_SIGN_IN',
    SELECT_PLAN: 'SELECT_PLAN',
    NOTHING: 'NOTHING',
};
const initialState = {
    accessToken: null,
    isLoading: false,
    loginState: exports.LoginState.IS_NEED_LOGIN,
    errors: [],
    errorMessage: '',
};
// create your action
exports.saveAccessTokenAction = (0, toolkit_1.createAction)('saveAccessToken');
exports.setLoginStateAction = (0, toolkit_1.createAction)('setLoginState');
exports.setErrorsAction = (0, toolkit_1.createAction)('setErrors');
exports.setErrorMessageAction = (0, toolkit_1.createAction)('setErrorMessage');
exports.setProfileAction = (0, toolkit_1.createAction)('setProfile');
exports.setUpdateProfileErrorAction = (0, toolkit_1.createAction)('setUpdateProfileErrorAction');
const loginSlice = (0, toolkit_1.createSlice)({
    name: 'login',
    initialState,
    reducers: {
        login: (state, action) => {
            return { ...state, action, isLoading: true };
        },
    },
    // child action of login
    extraReducers: builder => {
        builder.addCase(exports.saveAccessTokenAction, (state, action) => {
            return {
                ...state,
                accessToken: action.payload,
            };
        });
        builder.addCase(exports.setLoginStateAction, (state, action) => {
            return {
                ...state,
                loginState: action.payload,
                isLoading: false,
            };
        });
        builder.addCase(exports.setErrorsAction, (state, action) => {
            return {
                ...state,
                errors: action.payload,
                isLoading: false,
            };
        });
        builder.addCase(exports.setErrorMessageAction, (state, action) => {
            return {
                ...state,
                errorMessage: action.payload,
                isLoading: false,
            };
        });
    },
});
exports.loginReducer = loginSlice.reducer;
exports.default = loginSlice;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_native_1 = require("react-native");
const scale_1 = require("../../utils/scale");
const lodash_1 = require("lodash");
const constants_1 = require("../../constants");
exports.default = react_native_1.StyleSheet.create({
    container: {
        alignItems: "center",
        justifyContent: "space-between",
        position: "absolute",
        bottom: 40,
    },
    buttonContainer: {
        height: (0, scale_1.scale)(52),
        width: (0, lodash_1.min)([400, react_native_1.Dimensions.get("window").width * 0.7]),
        justifyContent: "center",
        marginBottom: (0, scale_1.scale)(40),
        marginTop: (0, scale_1.scale)(20),
        alignItems: "center",
        borderRadius: (0, scale_1.scale)(26),
        marginHorizontal: 40,
    },
    titleText: {
        color: "#0E0E0E",
        marginTop: 30,
        ...constants_1.FontFamily.NotoSerif700,
        fontSize: 24,
        textAlign: "center",
        marginHorizontal: 30,
        width: (0, lodash_1.min)([400, react_native_1.Dimensions.get("window").width * 0.7]),
    },
    messageText: {
        color: "#0E0E0E",
        marginHorizontal: 30,
        marginBottom: 30,
        textAlign: "center",
        marginTop: 20,
        lineHeight: 30,
        ...constants_1.FontFamily.NotoSerif700,
        fontSize: 14,
        width: (0, lodash_1.min)([400, react_native_1.Dimensions.get("window").width * 0.7]),
    },
});

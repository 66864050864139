"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const actions_1 = require("./actions");
const initState = {
    dataHome: null,
};
function homeReducer(state = initState, action) {
    switch (action.type) {
        case actions_1.GET_DATA_HOME:
            return {
                ...state,
                dataHome: action.data,
                isLoading: true,
            };
        case actions_1.GET_DATA_HOME_SUCCESS:
            return {
                ...state,
                dataHome: action.data,
                isLoading: false,
            };
        case actions_1.GET_DATA_HOME_FAILED:
            return {
                ...state,
                isLoading: false,
            };
        default:
            return state;
    }
}
exports.default = homeReducer;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_native_1 = require("react-native");
const scale_1 = require("../../../../utils/scale");
exports.default = react_native_1.StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#FCF9F2',
    },
    headerContainer: {
        backgroundColor: '#F3F3F3',
        justifyContent: 'flex-start',
        height: 70,
        width: '100%',
        position: 'absolute',
        flexDirection: 'row',
        alignItems: 'center',
        top: 0,
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.2,
        marginBottom: 10,
        elevation: 2,
        zIndex: 10,
    },
    headerText: {
        color: '#CF7285',
        fontFamily: 'Noto Serif JP',
        fontSize: 20,
        fontWeight: 'bold',
        marginLeft: 10,
        alignSelf: 'center',
        marginBottom: 4,
    },
    scrollContainer: {
        flex: 1,
    },
    inquryText: {
        color: '#A5A5A5',
        fontFamily: 'Noto Serif JP',
        fontSize: 14,
        fontWeight: 'bold',
        marginHorizontal: 20,
        marginBottom: 9,
    },
    inputText: {
        color: 'black',
        fontFamily: 'Noto Serif JP',
        fontSize: (0, scale_1.moderateScale)(14),
        fontWeight: '300',
        paddingHorizontal: (0, scale_1.scale)(10),
        lineHeight: (0, scale_1.moderateScale)(20),
    },
    input: {
        height: 53,
        marginHorizontal: 20,
        borderRadius: 10,
        marginTop: 10,
        color: 'black',
        paddingHorizontal: 20,
        includeFontPadding: false,
        fontFamily: 'Noto Serif JP',
        ...react_native_1.Platform.select({
            web: {
                outlineStyle: 'none',
            },
        }),
    },
    inputArea: {
        height: 200,
        marginHorizontal: 20,
        borderRadius: 10,
        marginTop: 10,
        marginBottom: 20,
        color: 'black',
        paddingHorizontal: 20,
        fontFamily: 'Noto Serif JP',
    },
    buttonContainer: {
        height: 52,
        marginHorizontal: 41,
        justifyContent: 'center',
        marginBottom: 30,
        alignItems: 'center',
        borderRadius: 26,
        marginTop: (0, scale_1.scale)(25),
        zIndex: 99,
        alignSelf: 'center',
    },
    sexText: {
        color: 'black',
        fontFamily: 'Noto Serif JP',
        backgroundColor: '#e6e6e6',
        padding: 10,
        alignSelf: 'flex-start',
        borderRadius: (0, scale_1.scale)(10),
        overflow: 'hidden',
        width: '90%',
        textAlign: 'center',
    },
    textError: {
        fontFamily: 'Noto Serif JP',
        fontSize: (0, scale_1.scale)(13),
        color: '#FF0000',
        marginHorizontal: 20,
        marginBottom: 10,
    },
    textSuccess: {
        fontFamily: 'Noto Serif JP',
        fontSize: 16,
        color: '#0E0E0E',
        marginTop: (0, scale_1.scale)(90),
    },
    yearPicker: {
        width: scale_1.width - 40,
        zIndex: 100,
        paddingHorizontal: (0, scale_1.scale)(10),
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 5,
        backgroundColor: 'white',
        marginHorizontal: 20,
    },
    loadingIndicator: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundColor: '#F5FCFF88',
        alignItems: 'center',
        justifyContent: 'center',
    },
    viewTextInput: {
        marginHorizontal: (0, scale_1.scale)(20),
        borderLeftWidth: (0, scale_1.scale)(4),
        borderLeftColor: '#CF7285',
        height: (0, scale_1.scale)(29),
        justifyContent: 'center',
        marginTop: (0, scale_1.scale)(20),
    },
    line: {
        height: 1,
        backgroundColor: '#E1E1E1',
        marginHorizontal: 20,
        marginTop: (0, scale_1.scale)(18),
    },
    errorWarning: {
        width: '100%',
        paddingHorizontal: (0, scale_1.scale)(20),
        // marginVertical: scale(12),
        fontFamily: 'Noto Serif JP',
        fontSize: (0, scale_1.scale)(13),
        color: '#FF0000',
    }
});

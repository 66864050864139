"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_native_1 = require("react-native");
const icons_1 = require("../../assets/icons");
const scale_1 = require("../../utils/scale");
var newOffset = [];
const VScrollPicker = (props) => {
    const { itemShowNumber, height, data, onValueChange, initValue } = props;
    const [selectedIndex, setSelectedIndex] = (0, react_1.useState)(0);
    const [arrOffset, setArrOffset] = (0, react_1.useState)([]);
    const scrollViewRef = (0, react_1.useRef)(null);
    (0, react_1.useEffect)(() => {
        if (initValue && arrOffset.length === data.length) {
            const index = data.findIndex((e) => e == initValue);
            scrollToIndex(index);
            setSelectedIndex(index);
            onValueChange?.(initValue, index);
        }
    }, [initValue, arrOffset]);
    const onLayout = (e, index) => {
        newOffset[index] = e.nativeEvent.layout.y;
        if (data.length - 1 === index) {
            setArrOffset([...newOffset]);
        }
    };
    const scrollToIndex = (index) => {
        const numberItemEmpty = Math.round(itemShowNumber / 2);
        scrollViewRef.current?.scrollTo({
            x: 0,
            y: arrOffset[index - (numberItemEmpty - 1)],
            animated: false,
        });
        setSelectedIndex(index);
        if (onValueChange) {
            const selectedValue = data[index - (numberItemEmpty - 1)];
            onValueChange(selectedValue, index - (numberItemEmpty - 1));
        }
    };
    const handlePrevTime = () => {
        if (selectedIndex > 0) {
            const prevIndex = selectedIndex - 1;
            scrollToIndex(prevIndex);
            setSelectedIndex(prevIndex);
        }
    };
    const handleNextTime = () => {
        if (selectedIndex < data.length - 1) {
            const nextIndex = selectedIndex + 1;
            scrollToIndex(nextIndex);
            setSelectedIndex(nextIndex);
        }
    };
    const onMomentumScrollEnd = (e) => {
        const offset = e.nativeEvent.contentOffset?.y || 0;
        const itemHeight = height / itemShowNumber;
        const numberItemEmpty = Math.round(itemShowNumber / 2);
        const calculatedIndex = Math.round(offset / itemHeight);
        setSelectedIndex(calculatedIndex + numberItemEmpty - 1);
        if (onValueChange) {
            const selectedValue = data[calculatedIndex];
            onValueChange(selectedValue, calculatedIndex);
        }
    };
    const renderItem = (offset) => {
        const empty = Array.from({ length: itemShowNumber / 2 - 1 }, () => null);
        let modifiedData = empty.concat(data).concat(empty);
        if (offset === 1) {
            modifiedData = empty.concat(data).concat(empty).concat([null]);
        }
        return modifiedData.map((e, index) => {
            const isSelected = selectedIndex === index;
            let style = {
                color: "#cecece",
                fontSize: 20,
                fontWeight: "bold",
            };
            if (isSelected) {
                if (props.activeStyle) {
                    style = props.activeStyle;
                }
                else {
                    style = {
                        color: "black",
                        fontSize: 25,
                    };
                }
            }
            else {
                if (props.notActiveStyle) {
                    style = props.notActiveStyle;
                }
            }
            return ((0, jsx_runtime_1.jsx)(react_native_1.TouchableOpacity, { onPress: () => {
                    scrollToIndex(index);
                }, onLayout: (e) => onLayout(e, index), style: [
                    styles.defaultContainerStyle,
                    { height: height / itemShowNumber },
                    props.containerItemStyle,
                ], children: (0, jsx_runtime_1.jsx)(react_native_1.Text, { style: [{ textAlign: "center" }, style, props.textStyle], children: +e > 9 ? e : `0${e}` }) }, index));
        });
    };
    const heightItem = height / itemShowNumber;
    const offset = itemShowNumber % 2 === 0 ? 1 : 0;
    return ((0, jsx_runtime_1.jsxs)(react_native_1.View, { style: props.style, children: [(0, jsx_runtime_1.jsx)(react_native_1.ScrollView, { scrollEnabled: react_native_1.Platform.OS !== "web", 
                // showsVerticalScrollIndicator={false}
                onMomentumScrollEnd: onMomentumScrollEnd, scrollEventThrottle: 16, ref: scrollViewRef, snapToInterval: heightItem, snapToAlignment: "center", style: [styles.styleScrollview, { height: height }, props.scrollStyle], children: renderItem(offset) }), (0, jsx_runtime_1.jsxs)(react_native_1.View, { pointerEvents: "none", style: styles.overlayContainer, children: [(0, jsx_runtime_1.jsx)(react_native_1.View, { style: [
                            styles.lineOverlay,
                            { top: Math.round(itemShowNumber / 2) * heightItem },
                            props.styleLine,
                        ] }), (0, jsx_runtime_1.jsx)(react_native_1.View, { style: [
                            styles.lineOverlay,
                            {
                                bottom: (Math.round(itemShowNumber / 2) + offset) * heightItem,
                            },
                            props.styleLine,
                        ] })] }), react_native_1.Platform.OS === "web" && ((0, jsx_runtime_1.jsxs)(react_native_1.View, { style: {
                    position: "absolute",
                    right: (0, scale_1.scale)(5),
                    justifyContent: "center",
                    height: "100%",
                    paddingVertical: (0, scale_1.scale)(2),
                }, children: [(0, jsx_runtime_1.jsx)(react_native_1.TouchableOpacity, { onPress: handlePrevTime, children: (0, jsx_runtime_1.jsx)(icons_1.IC_BACK, { width: (0, scale_1.scale)(10), height: (0, scale_1.scale)(10), color: "black", style: { transform: [{ rotateZ: "90deg" }] } }) }), (0, jsx_runtime_1.jsx)(react_native_1.TouchableOpacity, { onPress: handleNextTime, style: { marginTop: (0, scale_1.scale)(5) }, children: (0, jsx_runtime_1.jsx)(icons_1.IC_BACK, { width: (0, scale_1.scale)(10), height: (0, scale_1.scale)(10), color: "black", style: { transform: [{ rotateZ: "-90deg" }] } }) })] }))] }));
};
const styles = react_native_1.StyleSheet.create({
    container: {
        flex: 1,
    },
    lineOverlay: {
        width: "100%",
        position: "absolute",
        height: 0.5,
        backgroundColor: "black",
    },
    overlayContainer: {
        position: "absolute",
        width: "100%",
        height: "100%",
    },
    styleScrollview: {
        height: 150,
    },
    defaultContainerStyle: {
        justifyContent: "center",
    },
});
exports.default = VScrollPicker;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_native_1 = require("react-native");
const config_1 = require("../../../web/firebase/config");
const ModalFirebaseAuthenticationWeb = (props) => {
    const { visible, phone, onClose, setConfirm, setError } = props;
    let recaptchaVerifier;
    (0, react_1.useEffect)(() => {
        if (visible) {
            recaptchaVerifier = new config_1.firebase.auth.RecaptchaVerifier('recaptcha-container', {
                size: 'invisible',
                callback: () => { },
            });
            if (phone != null && phone != '') {
                try {
                    config_1.firebase.auth().signOut();
                }
                catch (e) { }
                config_1.firebase
                    .auth()
                    .signInWithPhoneNumber(phone, recaptchaVerifier)
                    .then((confirmResult) => {
                    setConfirm(confirmResult);
                })
                    .catch((err) => {
                    setError('電話番号が存在しません。');
                });
            }
            else {
            }
        }
    }, [visible]);
    return ((0, jsx_runtime_1.jsx)(react_native_1.Modal, { visible: visible, animationType: "none", statusBarTranslucent: true, transparent: true, onRequestClose: () => {
            onClose();
        }, children: (0, jsx_runtime_1.jsx)(react_native_1.View, { id: "recaptcha-container" }) }));
};
exports.default = ModalFirebaseAuthenticationWeb;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = {
    THERAPYNAVIGATOR: 'THERAPYNAVIGATOR',
    THERAPISTNAVIGATOR: 'THERAPISTNAVIGATOR',
    MYPAGENAVIGATOR: 'MYPAGENAVIGATOR',
    SEMINARNAVIGATOR: 'SEMINARNAVIGATOR',
    UNAUTHENTICATED: 'UNAUTHENTICATED',
    MAINUNAUTHENTICATED: 'MAINUNAUTHENTICATED',
    LOGIN: 'LOGIN',
    SIGNUP: 'SIGNUP',
    REGISTERINFORMATION: 'REGISTERINFORMATION',
    MAINNAVIGATOR: 'MAINNAVIGATOR',
    MAINTABBAR: 'MAINTABBAR',
    HOMENAVIGATOR: 'HOMENAVIGATOR',
    HOME: 'HOME',
    MEMBER: 'MEMBER',
    THERAPY: 'THERAPY',
    THERAPIST: 'THERAPIST',
    SEMINAR: 'SEMINAR',
    SEMINARDETAIL: 'SEMINARDETAIL',
    GUIDE: 'GUIDE',
    MYPAGE: 'MYPAGE',
    EDITPROFILE: 'EDITPROFILE',
    FAQ: 'FAQ',
    INQUIRY: 'INQUIRY',
    THERAPISTDETAIL: 'THERAPISTDETAIL',
    THERAPYDETAIL: 'THERAPYDETAIL',
    RESERVATION: 'RESERVATION',
    TERMOFUSE: 'TERMOFUSE',
    POLICY: 'POLICY',
};

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_native_1 = require("react-native");
const scale_1 = require("../../../../utils/scale");
const constants_1 = require("../../../../constants");
exports.default = react_native_1.StyleSheet.create({
    container: {
        alignItems: "center",
        // justifyContent: 'space-between',
        // position: 'absolute',
        marginTop: (0, scale_1.scale)(20),
    },
    introText: {
        marginVertical: 20,
        marginHorizontal: 40,
        textAlign: "center",
        color: "#0E0E0E",
        ...constants_1.FontFamily.NotoSerif300,
        fontSize: 14,
    },
    headerText: {
        color: "#0E0E0E",
        marginTop: (0, scale_1.scale)(50),
        ...constants_1.FontFamily.NotoSerif700,
        fontSize: 24,
        textAlign: "center",
    },
    loadingIndicator: {
        position: "absolute",
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundColor: "#F5FCFF88",
        alignItems: "center",
        justifyContent: "center",
    },
    bottomContainer: {
        flexDirection: "row",
        // marginBottom: 20,
        marginHorizontal: 20,
        width: (0, scale_1.moderateScale)(200),
        paddingBottom: (0, scale_1.scale)(40),
    },
    cancel: {
        color: "#797E80",
        marginTop: (0, scale_1.scale)(50),
        ...constants_1.FontFamily.NotoSerif700,
        fontSize: (0, scale_1.moderateScale)(13),
        textAlign: "left",
    },
    withdraw: {
        color: "#CE146A",
        marginTop: (0, scale_1.scale)(50),
        ...constants_1.FontFamily.NotoSerif700,
        fontSize: (0, scale_1.moderateScale)(13),
        textAlign: "right",
    },
});

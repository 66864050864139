"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_native_1 = require("react-native");
const scale_1 = require("../../../../utils/scale");
const constants_1 = require("../../../../constants");
const numberOfItem = Math.round(react_native_1.Dimensions.get('screen').width / 293);
exports.default = react_native_1.StyleSheet.create({
    container: {
        width: 273,
        height: 221,
        alignItems: "flex-start",
    },
    therapyPreview: {
        flexDirection: "column",
        marginTop: 20,
        // marginHorizontal: 20,
        width: 273,
        overflow: "hidden",
        alignItems: "center",
    },
    therapyText: {
        color: "#1B1C20",
        ...constants_1.FontFamily.NotoSerif700,
        fontSize: (0, scale_1.moderateScale)(16),
        marginHorizontal: 12,
        marginTop: 5,
    },
    therapyDescriptionText: {
        color: "#1B1C20",
        ...constants_1.FontFamily.NotoSerif,
        fontSize: 10,
        marginHorizontal: 12,
    },
    therapistPreview: {
        flexDirection: "column",
        marginTop: 20,
        marginHorizontal: 20,
        flex: 1,
        overflow: "hidden",
        alignItems: "center",
    },
    therapistText: {
        color: "#1B1C20",
        ...constants_1.FontFamily.NotoSerif700,
        fontSize: (0, scale_1.fontsize)(12, 14),
        lineHeight: (0, scale_1.moderateScale)(20),
        marginHorizontal: 12,
        marginTop: 10,
    },
    seminarText: {
        color: "#1B1C20",
        ...constants_1.FontFamily.NotoSerif700,
        fontSize: (0, scale_1.moderateScale)(16),
        marginHorizontal: 12,
        marginTop: 0,
    },
    seminarDescriptionText: {
        color: "#1B1C20",
        ...constants_1.FontFamily.NotoSerif700,
        fontSize: (0, scale_1.fontsize)(12, 13),
        marginHorizontal: 12,
        marginTop: 10,
        marginBottom: 20,
    },
});

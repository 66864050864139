"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_native_1 = require("react-native");
const scale_1 = require("../../utils/scale");
const lodash_1 = require("lodash");
exports.default = react_native_1.StyleSheet.create({
    container: {
        alignItems: 'center',
        justifyContent: 'space-between',
        position: 'absolute',
        bottom: 40,
    },
    loginForm: {
        justifyContent: 'center',
    },
    buttonContainer: {
        height: (0, scale_1.scale)(40),
        width: (0, lodash_1.min)([400, react_native_1.Dimensions.get('window').width * 0.7]),
        justifyContent: 'center',
        marginBottom: 30,
        alignItems: 'center',
        borderRadius: (0, scale_1.scale)(20),
    },
    textButton: {
        color: 'white',
        fontWeight: 'bold',
        fontFamily: 'Noto Serif JP',
        position: 'absolute',
    },
    introText: {
        paddingTop: 70,
        width: '100%',
        textAlign: 'center',
        color: '#D68F9D',
        fontFamily: 'Noto Serif JP',
        fontSize: 18,
    },
    titleText: {
        color: '#0E0E0E',
        fontWeight: 'bold',
        marginTop: 30,
        fontFamily: 'Noto Serif JP',
        fontSize: 18,
        textAlign: 'center',
        marginHorizontal: 30,
    },
    messageText: {
        color: '#0E0E0E',
        fontWeight: '300',
        marginHorizontal: 30,
        marginVertical: 20,
        fontFamily: 'Noto Serif JP',
        fontSize: 14,
        textAlign: 'center',
    },
});

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_native_1 = require("react-native");
const scale_1 = require("../../../../utils/scale");
const constants_1 = require("../../../../constants");
exports.default = react_native_1.StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "#FCF9F2",
    },
    headerContainer: {
        backgroundColor: "#1B1C20",
        justifyContent: "center",
        height: react_native_1.Dimensions.get("screen").height / 3,
        width: "100%",
        alignItems: "center",
        shadowColor: "#000",
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.2,
        elevation: 2,
    },
    headerTextContainer: {
        height: (0, scale_1.scale)(75),
        top: (0, scale_1.scale)(-75),
        backgroundColor: "rgba(0, 0, 0, 0.7)",
        justifyContent: "center",
        alignitems: "center",
    },
    headerText: {
        color: "#FFFFFF",
        ...constants_1.FontFamily.NotoSerif700,
        fontSize: 14,
        marginHorizontal: 20,
        marginTop: 20,
    },
    titleText: {
        marginHorizontal: (0, scale_1.scale)(23),
        paddingBottom: (0, scale_1.scale)(20),
        marginTop: (0, scale_1.scale)(34),
        color: "#D68F9D",
        ...constants_1.FontFamily.NotoSerif700,
        fontSize: 18,
    },
    nameText: {
        color: "#FFFFFF",
        ...constants_1.FontFamily.NotoSerif700,
        fontSize: 20,
        marginHorizontal: 20,
        marginBottom: 20,
    },
    contentText: {
        marginHorizontal: (0, scale_1.scale)(23),
        color: "#0E0E0E",
        ...constants_1.FontFamily.NotoSerif700,
        fontSize: 14,
    },
    badgeContainer: {
        width: (0, scale_1.scale)(60),
        height: (0, scale_1.scale)(60),
        backgroundColor: "#BCA75C",
        borderRadius: (0, scale_1.scale)(30),
        justifyContent: "center",
        alignContent: "center",
        position: "absolute",
        bottom: 24 + (0, scale_1.scale)(75),
        right: 21,
    },
    badgeText: {
        color: "#725E30",
        ...constants_1.FontFamily.NotoSerif700,
        fontSize: 36,
        textAlign: "center",
    },
    contentContainer: {
        width: "100%",
        backgroundColor: "#FCF9F2",
        marginBottom: 80,
    },
    buttonContainer: {
        height: 80,
        backgroundColor: "#F3F3F3",
        justifyContent: "center",
        alignitems: "center",
        borderRadius: 0,
        position: "absolute",
        bottom: 0,
        left: 0,
        right: 0,
    },
});

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_native_1 = require("react-native");
const scale_1 = require("../../../../utils/scale");
exports.default = react_native_1.StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#FCF9F2',
    },
    headerContainer: {
        backgroundColor: '#F3F3F3',
        justifyContent: 'flex-start',
        height: 70,
        width: '100%',
        // position: 'absolute',
        flexDirection: 'row',
        alignItems: 'center',
        top: 0,
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.2,
        elevation: 2,
    },
    headerText: {
        color: '#CF7285',
        fontFamily: 'Noto Serif JP',
        fontSize: 20,
        fontWeight: 'bold',
        marginLeft: 10,
    },
    scrollContainer: {
        height: '100%',
        width: '100%',
        paddingTop: (0, scale_1.scale)(10),
        paddingBottom: (0, scale_1.scale)(100),
    },
    bodyContainer: {
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 20,
        marginHorizontal: 20,
        marginBottom: 10,
        overflow: 'hidden',
    },
    rowContainer: {
        justifyContent: 'center',
        alignItems: 'flex-start',
        backgroundColor: '#E8E8E8',
        height: 60,
        width: '100%',
    },
    aContainer: {
        justifyContent: 'center',
        alignItems: 'flex-start',
        backgroundColor: '#F2A78D',
        width: '100%',
    },
    questionText: {
        color: '#0E0E0E',
        fontFamily: 'Noto Serif JP',
        fontSize: 14,
        fontWeight: 'bold',
        marginHorizontal: 20,
        marginVertical: 10,
    },
    answerText: {
        color: '#FFFFFF',
        fontFamily: 'Noto Serif JP',
        fontSize: 14,
        fontWeight: 'bold',
        marginHorizontal: 20,
        marginVertical: 20,
    },
    footerContainer: {
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: 30,
    },
    changePasswordContainer: {
        justifyContent: 'center',
        alignItems: 'center',
        borderColor: '#334856',
        borderWidth: 0.5,
        borderRadius: 25,
        height: 50,
        width: 200,
        marginTop: 40,
    },
    transferContainer: {
        justifyContent: 'center',
        alignItems: 'center',
        borderColor: '#334856',
        borderWidth: 0.5,
        borderRadius: 25,
        height: 50,
        width: 200,
        marginTop: 30,
    },
    changePasswordText: {
        color: '#334856',
        fontFamily: 'Noto Serif JP',
        fontSize: 14,
        fontWeight: '300',
    },
});

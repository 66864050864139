"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_native_svg_1 = __importStar(require("react-native-svg"));
const IcPassword = (props) => ((0, jsx_runtime_1.jsx)(react_native_svg_1.default, { xmlns: "http://www.w3.org/2000/svg", width: 16.69, height: 20.618, viewBox: "0 0 16.69 20.618", ...props, children: (0, jsx_runtime_1.jsxs)(react_native_svg_1.G, { id: "Group_100", "data-name": "Group 100", transform: "translate(-4959.375 -1981.875)", children: [(0, jsx_runtime_1.jsx)(react_native_svg_1.Path, { id: "Path_114", "data-name": "Path 114", d: "M4974.266,1991.039h-.164V1987.6a5.733,5.733,0,0,0-5.727-5.727h-1.31a5.734,5.734,0,0,0-5.727,5.727v3.436h-.164a1.8,1.8,0,0,0-1.8,1.8v5.236a4.423,4.423,0,0,0,4.418,4.418h7.854a4.423,4.423,0,0,0,4.418-4.418v-5.236A1.8,1.8,0,0,0,4974.266,1991.039Zm-11.945-3.436a4.751,4.751,0,0,1,4.745-4.746h1.31a4.751,4.751,0,0,1,4.745,4.746v3.436h-10.8Zm9.327,13.909h-7.854a3.441,3.441,0,0,1-3.437-3.436v-5.236a.819.819,0,0,1,.818-.818h13.091a.819.819,0,0,1,.818.818v5.236A3.44,3.44,0,0,1,4971.647,2001.511Z", fill: "#fff" }), (0, jsx_runtime_1.jsx)(react_native_svg_1.Path, { id: "Path_115", "data-name": "Path 115", d: "M5049.866,2131.875a.491.491,0,0,0-.491.491v2.618a.491.491,0,1,0,.982,0v-2.618A.491.491,0,0,0,5049.866,2131.875Z", transform: "translate(-82.145 -136.909)", fill: "#fff" })] }) }));
exports.default = IcPassword;

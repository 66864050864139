"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_native_1 = require("react-native");
const scale_1 = require("../../../../utils/scale");
exports.default = react_native_1.StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: '#FCF9F2',
    },
    headerContainer: {
        backgroundColor: '#1B1C20',
        justifyContent: 'center',
        height: react_native_1.Dimensions.get('screen').height / 3,
        width: '100%',
        alignItems: 'center',
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.2,
        elevation: 2,
    },
    headerTextContainer: {
        height: (0, scale_1.scale)(75),
        top: (0, scale_1.scale)(-75),
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        justifyContent: 'center',
    },
    contentsContainer: {
        top: (0, scale_1.scale)(-75),
        justifyContent: 'flex-start',
        alignitems: 'center',
        marginTop: (0, scale_1.scale)(35),
    },
    headerText: {
        color: '#FFFFFF',
        fontFamily: 'Noto Serif JP',
        fontSize: 20,
        fontWeight: 'bold',
        marginHorizontal: 20,
        marginBottom: 20,
    },
    titleText: {
        marginHorizontal: 23,
        paddingBottom: (0, scale_1.scale)(20),
        color: '#D68F9D',
        fontFamily: 'Noto Serif JP',
        fontSize: 18,
        fontWeight: 'bold',
    },
    contentText: {
        marginHorizontal: 23,
        color: '#0E0E0E',
        fontFamily: 'Noto Serif JP',
        fontSize: 14,
        fontWeight: 'bold',
    },
    contentContainer: {
        width: '100%',
        backgroundColor: '#FCF9F2',
        marginBottom: 80,
    },
    buttonContainer: {
        height: 80,
        backgroundColor: '#F3F3F3',
        justifyContent: 'center',
        alignitems: 'center',
        borderRadius: 0,
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
    },
    buttonText: {
        color: '#FFFFFF',
        fontFamily: 'Noto Serif JP',
        fontSize: 16,
        fontWeight: 'bold',
        marginHorizontal: 10,
        position: 'absolute',
        left: 54,
    },
    seminarDateText: {
        color: '#CF7285',
        fontFamily: 'Noto Serif JP',
        fontSize: 14,
        fontWeight: 'bold',
        marginTop: 10,
        marginHorizontal: 20,
    },
});

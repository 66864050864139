"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.appReducer = exports.setShowGuildline = exports.setLoadedAppAction = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const initialState = {
    isLoaded: false,
    isShowGuildline: false,
};
exports.setLoadedAppAction = (0, toolkit_1.createAction)('setLoadedApp');
exports.setShowGuildline = (0, toolkit_1.createAction)('setShowGuildline');
const appSlice = (0, toolkit_1.createSlice)({
    name: 'app',
    initialState,
    reducers: {
        setAppRole: (state, action) => {
            return { ...state, appRole: action.payload };
        },
        logout: state => {
            return state;
        },
        withDraw: state => {
            return state;
        },
        initDataApp: () => { },
    },
    extraReducers: builder => {
        builder.addCase(exports.setLoadedAppAction, (state, action) => {
            return {
                ...state,
                isLoaded: action.payload,
            };
        });
        builder.addCase(exports.setShowGuildline, (state, action) => {
            return {
                ...state,
                isShowGuildline: action.payload,
            };
        });
    },
});
exports.appReducer = appSlice.reducer;
exports.default = appSlice;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_native_1 = require("react-native");
const scale_1 = require("../../../utils/scale");
const lodash_1 = require("lodash");
const devices_1 = require("../../../constants/devices");
const constants_1 = require("../../../constants");
exports.default = react_native_1.StyleSheet.create({
    container: {
        alignItems: "center",
        marginBottom: (0, scale_1.scale)(20),
        marginHorizontal: 40,
        maxWidth: "80%",
    },
    loginForm: {
        justifyContent: "center",
    },
    input: {
        height: (0, scale_1.scale)(52),
        borderRadius: (0, scale_1.scale)(20),
        backgroundColor: "#A5A5A5",
        marginTop: (0, scale_1.scale)(20),
        color: "white",
        paddingHorizontal: (0, scale_1.scale)(20),
        alignItems: "center",
        includeFontPadding: false,
        ...constants_1.FontFamily.NotoSerif,
        padding: 0,
        fontSize: (0, scale_1.moderateScale)(14),
        ...react_native_1.Platform.select({
            web: {
                outlineStyle: "none",
            },
        }),
        width: (0, lodash_1.min)([400, react_native_1.Dimensions.get("window").width * 0.7]),
    },
    loginTextButton: {
        color: "white",
        textAlign: "center",
        fontWeight: "500",
    },
    buttonContainer: {
        height: (0, scale_1.scale)(52),
        justifyContent: "center",
        marginBottom: (0, scale_1.scale)(20),
        marginTop: (0, scale_1.scale)(20),
        alignItems: "center",
        borderRadius: (0, scale_1.scale)(20),
        width: (0, lodash_1.min)([400, react_native_1.Dimensions.get("window").width * 0.7]),
    },
    imageContainer: {
        resizeMode: "contain",
        height: "30%",
        width: "100%",
    },
    button: {
        alignItems: "center",
        justifyContent: "center",
        height: (0, scale_1.scale)(40),
        width: "100%",
        borderRadius: (0, scale_1.scale)(20),
    },
    underlineButton: {
        color: "black",
        textDecorationLine: "underline",
        paddingBottom: (0, scale_1.scale)(10),
        ...constants_1.FontFamily.NotoSerif300,
        fontSize: (0, scale_1.scale)(14),
    },
    underlineButtonContainer: {
        alignItems: "center",
        justifyContent: "center",
        marginTop: 5,
        paddingBottom: (0, devices_1.isPhone)() ? 10 : 0,
        borderRadius: 25,
    },
    textButton: {
        color: "white",
        ...constants_1.FontFamily.NotoSerif700,
        position: "absolute",
    },
    loginAccountText: {
        color: "#0E0E0E",
        marginTop: (0, scale_1.scale)(50),
        ...constants_1.FontFamily.NotoSerif700,
        fontSize: (0, scale_1.moderateScale)(24),
        textAlign: "center",
    },
    loadingIndicator: {
        position: "absolute",
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundColor: "#F5FCFF88",
        alignItems: "center",
        justifyContent: "center",
    },
    err: {
        width: (0, lodash_1.min)([400, react_native_1.Dimensions.get("window").width * 0.7]),
        color: "#FF0000",
        fontSize: (0, scale_1.moderateScale)(13),
        marginBottom: (0, scale_1.scale)(10),
        marginTop: 5,
    },
});

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatString = void 0;
function formatString(template, ...args) {
    return template.replace(/{(\d+)}/g, (match, index) => {
        return typeof args[index] !== 'undefined' ? args[index] : match;
    });
}
exports.formatString = formatString;
const ValidationMessages = {
    required: '{0}は必須項目です。',
    email: 'にはメールアドレスの形式で入力してください。',
    booking_therapist_required: "{0}は必ず入力してください",
    booking_therapist_kana_format: "{0}はカタカナで入力してください。",
    booking_therapist_email_format: "{0}は正しい形式で入力してください",
    booking_therapist_phone_length: "{0}は、10桁から11桁にしてください。",
    booking_therapist_phone_start_zero: "{0}に正しい形式を指定してください。",
    booking_therapist_time_before_current: "{0}は現在時刻より未来の時刻を設定してください。",
    phone_required: "{0}は必ず指定してください"
};
exports.default = ValidationMessages;

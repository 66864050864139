"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_native_1 = require("react-native");
const scale_1 = require("../../../../utils/scale");
const lodash_1 = require("lodash");
const constants_1 = require("../../../../constants");
exports.default = react_native_1.StyleSheet.create({
    container: {
        alignItems: "center",
        // justifyContent: 'space-between',
        // position: 'absolute',
        marginHorizontal: 20,
        marginBottom: 10,
    },
    loginForm: {
        justifyContent: "center",
    },
    input: {
        height: (0, scale_1.scale)(52),
        width: (0, lodash_1.min)([400, react_native_1.Dimensions.get("window").width * 0.7]),
        borderRadius: (0, scale_1.scale)(26),
        backgroundColor: "#A5A5A5",
        color: "white",
        alignItems: "center",
        includeFontPadding: false,
        ...constants_1.FontFamily.NotoSerif,
        padding: 0,
        paddingHorizontal: 20,
        marginBottom: 10,
        fontSize: (0, scale_1.moderateScale)(14),
        ...react_native_1.Platform.select({
            web: {
                outlineStyle: "none",
            },
        }),
    },
    buttonContainer: {
        height: (0, scale_1.scale)(52),
        width: (0, lodash_1.min)([400, react_native_1.Dimensions.get("window").width * 0.7]),
        justifyContent: "center",
        marginBottom: (0, scale_1.scale)(30),
        marginTop: (0, scale_1.scale)(20),
        alignItems: "center",
        borderRadius: (0, scale_1.scale)(26),
    },
    button: {
        alignItems: "center",
        justifyContent: "center",
        height: (0, scale_1.scale)(40),
        width: "100%",
        borderRadius: (0, scale_1.scale)(20),
    },
    introText: {
        marginVertical: 20,
        marginHorizontal: 20,
        width: (0, lodash_1.min)([400, react_native_1.Dimensions.get("window").width * 0.7]),
        textAlign: "center",
        color: "#0E0E0E",
        ...constants_1.FontFamily.NotoSerif700,
        fontSize: 14,
    },
    textInput: {
        marginBottom: 10,
        marginTop: 20,
        textAlign: "left",
        color: "#0E0E0E",
        ...constants_1.FontFamily.NotoSerif700,
        fontSize: 13,
        marginLeft: 15,
        includeFontPadding: false,
        textAlignVertical: "bottom",
        overflow: "hidden",
    },
    headerText: {
        color: "#0E0E0E",
        marginTop: (0, scale_1.scale)(50),
        ...constants_1.FontFamily.NotoSerif700,
        fontSize: 24,
        textAlign: "center",
    },
    loadingIndicator: {
        position: "absolute",
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundColor: "#F5FCFF88",
        alignItems: "center",
        justifyContent: "center",
    },
    err: {
        width: (0, lodash_1.min)([400, react_native_1.Dimensions.get("window").width * 0.7]),
        color: "#FF0000",
        fontSize: 13,
        marginVertical: (0, scale_1.scale)(10),
    },
});
